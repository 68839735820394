/*====================================
		===[ Default Style ]===
======================================*/
	/* Variables */
	body {
	    font-family: 'Montserrat', sans-serif;
	    overflow-x: hidden;
	}
	p {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 14px;
	    color: #727272;
	    line-height: 24px;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	}
	h2 {
	    margin: 0;
	    text-align: center;
	    color: #333333;
	    font-size: 48px;
	    position: relative;
	}
	h3 {
	    line-height: 26px;
	    margin: 0;
	    padding-bottom: 20px;
	    color: #333333;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.section-header-box {
	    margin-bottom: 28px;
	}
	.section-header,
	.section-header-l {
	    padding-bottom: 25px;
	    text-align: center;
	}
	.section-header:before,
	.section-header-l:before {
	    border-bottom: 1px solid #b2b2b2;
	    bottom: 15px;
	    content: "";
	    height: 2px;
	    left: 50%;
	    position: absolute;
	    transform: translateX(-50%);
	    width: 100px;
	}
	.section-header p,
	.section-header-l p {
	    margin: 0;
	}
	.section-header-l h2 {
	    text-align: left;
	}
	.section-header-l:before {
	    left: 15px;
	    transform: none;
	}
	/* -------- Pre Loader -------- */
	
	#preloader {
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: #fefefe;
	    z-index: 9999;
	}
	#preloader #status {
	    width: 200px;
	    height: 200px;
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    background-image: url("../images/preloader.gif");
	    background-repeat: no-repeat;
	    background-position: center;
	    margin: -100px 0 0 -100px;
	}
	.view_more_btn {
	    text-align: center;
	    margin: 18px 0;
	}
	.view_more_btn .view-more-item {
	    background: #ec7a11;
	    color: #333333;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    padding: 18px 50px;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	/*=======[ Index Search Style ]========*/
	
	#search {
	    position: fixed;
	    top: 0px;
	    left: 0px;
	    background-color: rgba(0, 0, 0, 0.95);
	    opacity: 0;
	}
	#search.open {
	    opacity: 1;
	    z-index: 1000;
	    width: 100%;
	    height: 100%;
	}
	#search input[type="search"] {
	    position: absolute;
	    top: 50%;
	    width: 100%;
	    color: #fff;
	    background: transparent;
	    font-size: 40px;
	    font-weight: 300;
	    text-align: center;
	    border: 0px;
	    margin: 0px auto;
	    margin-top: -51px;
	    padding-left: 30px;
	    padding-right: 30px;
	    outline: none;
	}
	#search .btn {
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    margin-top: 50px;
	    margin-left: -40px;
	    background: #ec7a11;
	    border-radius: 2px;
	    padding: 7px 16px;
	    color: #333333;
	    font-weight: 700;
	}
	#search .close {
	    background-color: #ec7a11;
	    border-color: #ffb600;
	    border-radius: 3px;
	    color: #333333;
	    font-size: 21px;
	    opacity: 1;
	    padding: 0 0px 4px;
	    position: fixed;
	    right: 15px;
	    top: 15px;
	    height: 30px;
	    width: 32px;
	    text-align: center;
	}
	/*=================================================
		===[ Home Version 01 ]===
===================================================*/
	header {
	    /*=======[ Index Navbar Style ]========*/
	    /*=======[ Index Header Body Style ]========*/
	    /*=======[ Index Rev Slider Style ]========*/
	    /*=======[ Index Rev Slider btn  Style ]========*/
	    /*slider btn style*/
	}
	header .header-top {
	    background: rgb(42, 56, 165);
	}
	header .header-top .header-top-left ul {
	    margin: 0;
	    padding: 22px 0;
	}
	header .header-top .header-top-left ul li {
	    padding-right: 15px;
	    display: inline-block;
	    color: #ffffff;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    color: #ffffff;
	    font-size: 13px;
	}
	header .header-top .header-top-left ul li .top-icon {
	    font-size: 11px;
	    padding-right: 5px;
	}
	header .header-top .header-top-right ul {
	    margin: 0;
	    padding: 15px 0;
	    text-align: right;
	}
	header .header-top .header-top-right ul li {
	    display: inline-block;
	}
	header .header-top .header-top-right ul li a {
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 13px;
	    text-decoration: none;
	}
	header .header-top .header-top-right ul li a .top-icon {
	    font-size: 11px;
	    padding-right: 5px;
	}
	header .header-body {
	    position: absolute;
	    width: 100%;
	    /*=======[ Index Sticky Nav Style ]========*/
	}
	header .header-body .edu-navbar {
	    padding: 20px 0;
	    margin-bottom: 0;
	    background: transparent;
	    border-radius: 0px;
	    z-index: 10;
	}
	header .header-body .edu-navbar .navbar-header a img {
		float: left;
	}
	header .header-body .edu-navbar .navbar-header a span {
	    color: #ffffff;
	    font-size: 20px;
	    text-transform: uppercase;
	    font-weight: 700;
	    font-family: 'Montserrat', sans-serif;
	    padding-left: 5px;
	    line-height: 30px;
	    letter-spacing: 1px;
	}
	header .header-body .edu-navbar .edu-nav {
	    padding-right: 0;
	}
	header .header-body .edu-navbar .edu-nav .nav li {
	    margin-left: 40px;
	    position: relative;
	}
	header .header-body .edu-navbar .edu-nav .nav li a.unhovered {
	    padding: 23px 0;
	    margin-bottom: 0px;
	}
	header .header-body .edu-navbar .edu-nav .nav li a.unhovered:before {
	    display: none;
	}
	header .header-body .edu-navbar .edu-nav .nav li a {
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	    color: #fff;
	    text-transform: uppercase;
	    font-size: 14px;
	    padding: 14px 0;
	    margin-bottom: 15px;
	    position: relative;
	    text-decoration: none;
	}
	header .header-body .edu-navbar .edu-nav .nav li a:before {
	    background: #fff;
	    height: 2px;
	    width: 100%;
	    bottom: 0px;
	    left: 0;
	    content: "";
	    position: absolute;
	    width: 100%;
	    visibility: hidden;
	    opacity: 0;
	}
	header .header-body .edu-navbar .edu-nav .nav li a:hover,
	header .header-body .edu-navbar .edu-nav .nav li a:active,
	header .header-body .edu-navbar .edu-nav .nav li a:focus {
	    background: transparent;
	    outline: none;
	    color: #fff;
	}
	header .header-body .edu-navbar .edu-nav .nav li.active a:before,
	header .header-body .edu-navbar .edu-nav .nav li:hover a::before,
	header .header-body .edu-navbar .edu-nav .nav li:focus a::before {
	    visibility: visible;
	    opacity: 1;
	    background: #fff;
	    height: 2px;
	    width: 100%;
	    bottom: 5px;
	    left: 0;
	    content: "";
	    position: absolute;
	    width: 100%;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown {
	    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
	    transition: all 0.3s ease-in-out;
	    padding: 15px 0px;
	    min-width: 225px;
	    background: #ffffff;
	    left: 0px;
	    position: absolute;
	    top: 100%;
	    z-index: 999;
	    transition: all ease-in-out .3s;
	    transform-origin: 0 0 0;
	    position: absolute;
	    text-align: left;
	    visibility: hidden;
	    opacity: 0;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown .dropdown-list-box-02 {
	    position: relative;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown .dropdown-list-box-02:hover .dropdown-list_2 {
	    opacity: 1;
	    visibility: visible;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li {
	    margin-left: 0;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li:last-child {
	    border-bottom: 0;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a {
	    font-size: 14px;
	    text-transform: none;
	    font-weight: 400;
	    display: block;
	    padding: 10px 30px;
	    margin-bottom: 0;
	    color: #333333;
	    transition: all ease-in-out 0.3s;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a:hover {
	    color: #ec7a11;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a:before {
	    display: none;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a .fa-angle-right {
	    color: #000;
	    float: right;
	    font-size: 20px;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 {
	    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
	    transition: all 0.3s ease-in-out;
	    padding: 15px 0px;
	    min-width: 225px;
	    background: #ffffff;
	    left: 225px;
	    position: absolute;
	    top: 0px;
	    z-index: 999;
	    transition: all ease-in-out .3s;
	    transform-origin: 0 0 0;
	    position: absolute;
	    text-align: left;
	    opacity: 0;
	    visibility: hidden;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list-box-03 {
	    position: relative;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list-box-03:hover .dropdown-list_3 {
	    opacity: 1;
	    visibility: visible;
	}
	header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list_3 {
	    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
	    transition: all 0.3s ease-in-out;
	    padding: 15px 0px;
	    min-width: 225px;
	    background: #ffffff;
	    left: 225px;
	    position: absolute;
	    top: 0px;
	    z-index: 999;
	    transition: all ease-in-out .3s;
	    transform-origin: 0 0 0;
	    position: absolute;
	    text-align: left;
	    opacity: 0;
	    visibility: hidden;
	}
	header .header-body .edu-navbar .edu-nav .nav li:hover ul.dropdown {
	    opacity: 1;
	    visibility: visible;
	}
	header .header-body .edu-navbar .edu-nav .nav li:hover > a {
	    color: #fff;
	}
	header .header-body .edu-navbar .edu-nav .nav li.active a {
	    color: #fff;
	}
	header .header-body .edu-navbar .edu-nav .nav li.active a:before {
	    width: 100%;
	    opacity: 1;
	    visibility: visible;
	}
	header .header-body .is-sticky .navbar-header a span {
	    color: #333333;
	}
	header .header-body .is-sticky .edu-navbar {
	    padding: 20px 0;
	    background: #ffffff;
	    z-index: 1000;
	    transition: 0.5s ease;
	    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
	    transition: all 0.3s ease-in-out;
	}
	header .header-body .is-sticky .edu-navbar .edu-nav .nav li a {
	    color: #333333;
	}
	header .header-body .is-sticky .edu-navbar .edu-nav .nav li .dropdown li a {
	    color: #333333;
	}
	header .header-body .is-sticky .edu-navbar .edu-nav .nav li.active a,
	header .header-body .is-sticky .edu-navbar .edu-nav .nav li:hover a,
	header .header-body .is-sticky .edu-navbar .edu-nav .nav li:focus a {
	    color: #ec7a11;
	}
	header .header-body .logo-color {
	    display: none;
	}
	header .header-body .is-sticky .logo-color {
	    display: block;
	}
	header .header-body .logo-white {
	    display: block;
	}
	header .header-body .is-sticky .logo-white {
	    display: none;
	}
	header .rev_slider_wrapper {
	    position: relative;
	}
	header .rev_slider_wrapper ul li .slider-overlay {
	    height: 100%;
		background: #000;
		opacity: 0.4;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}
	header .rev_slider_wrapper ul li .slider-overlay:before {
	    background: rgba(0, 0, 0, 0.2);
	    content: "";
	    height: 100%;
	    left: 0;
	    position: absolute;
	    top: 0;
	    transition: all 0.3s ease 0s;
	    width: 100%;
	    z-index: -1;
	}
	header .el-btn-regular {
	    background: transparent;
	    border: 1px solid #ffffff;
	    border-radius: 0px;
	    color: #ffffff;
	    text-decoration: none;
	    display: inline-block;
	    font-size: 15px;
	    font-weight: 500;
	    padding: 15px 25px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    text-transform: uppercase;
	    transition: all 0.3s ease-in-out !important;
	}
	header .el-btn-regular:hover {
	    background: #ec7a11;
	    border: 1px solid #ec7a11;
	    color: #333333;
	    transition: all 0.3s ease-in-out;
	}
	header .slider-btn-left {
	    margin-right: 10px;
	    color: #333333;
	    border: 1px solid #ec7a11;
	    background: #ec7a11;
	}
	header .tparrows.tp-leftarrow::before {
	    content: "";
	}
	header .tp-leftarrow:hover {
	    background: url("../images/index/arrow-left-hover.png") no-repeat center center/cover;
	    border-radius: 100%;
	}
	header .tp-leftarrow {
	    background: url("../images/index/arrow-left.png") no-repeat center center/cover;
	    border-radius: 100%;
	    height: 48px;
	    width: 48px;
	    transition: all 0.3s ease-in-out;
	}
	header .tparrows.tp-rightarrow::before {
	    content: "";
	}
	header .tp-rightarrow:hover {
	    background: url("../images/index/arrow-right-hover.png") no-repeat center center/cover;
	    border-radius: 100%;
	}
	header .tp-rightarrow {
	    background: url("../images/index/arrow-right.png") no-repeat center center/cover;
	    border-radius: 100%;
	    height: 48px;
	    width: 48px;
	    transition: all 0.3s ease-in-out;
	}
	/*=======[ Index Rev Slider Content Style ]========*/
	
	.slider-content-bottom {
	    background: #ffffff;
	    text-align: center;
	    margin-top: -100px;
	}
	.slider-content-bottom .sider-btm-row-inner {
	    padding: 0 15px;
	}
	.slider-content-bottom .slide_cont_box_01 {
	    padding: 0 4px 0 0;
	}
	.slider-content-bottom .slide_cont_box_02 {
	    padding: 0 4px 0 4px;
	}
	.slider-content-bottom .slide_cont_box_03 {
	    padding: 0 0 0 4px;
	}
	.slider-content-bottom .content_body:hover .btm-item_01::before {
	    background: #ec7a11;
	}
	.slider-content-bottom .content_body:hover .btm-item_02::before {
	    background: #ec7a11;
	}
	.slider-content-bottom .content_body:hover .btm-item_03::before {
	    background: #ec7a11;
	}
	.slider-content-bottom .btm-item_01,
	.slider-content-bottom .btm-item_02,
	.slider-content-bottom .btm-item_03 {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    position: relative;
	    z-index: 1;
	    padding: 30px 30px;
	}
	.slider-content-bottom .btm-item_01::before,
	.slider-content-bottom .btm-item_02::before,
	.slider-content-bottom .btm-item_03::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100%;
	    width: 100%;
	    z-index: -1;
	    content: "";
	    transition: all 0.3s ease-in-out;
	}
	.slider-content-bottom .btm-item_01:hover,
	.slider-content-bottom .btm-item_02:hover,
	.slider-content-bottom .btm-item_03:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.slider-content-bottom .btm-item_01::before {
	    background: rgba(42, 56, 165, 1);
	}
	.slider-content-bottom .btm-item_02::before {
	    background: rgba(42, 56, 165, 1);
	}
	.slider-content-bottom .btm-item_03::before {
	    background: rgba(42, 56, 165, 1);
	}
	.slider-content-bottom .slider-btm-box .btm-item-icon {
	    height: 120px;
	    width: 90px;
	    padding-bottom: 30px;
	}
	.slider-content-bottom .slider-btm-box h3 {
	    color: #ffffff;
	    font-size: 16px;
	    padding: 0;
	}
	.slider-content-bottom .slider-btm-box p {
	    color: #ffffff;
		margin: 0;
		font-size: 12px;
	    padding: 15px 0;
	}
	.slider-content-bottom .slider-btm-box a {
	    color: #fff;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.slider-content-bottom .slider-btm-box a .slider-b-btn-icon {
	    padding-left: 10px;
	}
	/*=======[ Index Wellcome Area Style ]========*/
	
	.Welcome-area {
	    background: #ffffff;
		padding-top: 100px;
		padding-bottom: 50px;
	}
	.Welcome-area .Welcome-area-text {
	    margin-bottom: 50px;
	    margin-top: 40px;
	}
	.Welcome-area .Welcome-area-text p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	    padding-bottom: 25px;
	}
	.Welcome-area .read_more-btn {
	    color: #666;
	    font-size: 14px;
	    text-transform: uppercase;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    position: relative;
	    padding-right: 15px;
	}
	.Welcome-area .read_more-btn:before {
	    position: absolute;
	    font-family: FontAwesome;
	    content: "\f178";
	    top: 1px;
	    right: -15px;
	}
	.Welcome-area .welcome-img {
	    padding: 0;
	    margin-top: 33px;
	}
	.Welcome-area .welcome-img img {
	    transform: scale(1.05);
	    margin-bottom: 11px;
	}
	/*=======[ Index Courses Area Style ]========*/
	
	.Courses-area {
	    background: #f9f9f9;
	    padding: 100px 0;
	}
	.Courses-area .courses-r-margin-bottom {
	    margin-bottom: 30px;
	}
	.Courses-area .courses-r-margin-bottom:last-child {
	    margin-bottom: 0;
	}
	.Courses-area .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.Courses-area .single-courses figure {
	    position: relative;
	}
	.Courses-area .single-courses figure:before {
	    background: rgba(0, 0, 0, 0.4);
	    content: "";
	    height: 0%;
	    opacity: 0;
	    position: absolute;
	    width: 100%;
	    z-index: 1;
	    visibility: hidden;
	    top: 0;
	    left: 0;
	    transition: all 0.3s ease-in-out;
	}
	.Courses-area .single-courses figure .figure-img {
	    position: relative;
	}
	.Courses-area .single-courses figure .figure-img img {
	    width: 100%;
	    height: auto;
	}
	.Courses-area .single-courses figure .figure-img::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100%;
	    width: 100%;
	    background: rgba(254, 199, 34, 0.1);
	    z-index: 1;
	    content: "";
	}
	.Courses-area .single-courses figure figcaption {
	    position: absolute;
	    text-align: center;
	    top: 0;
	    left: 0;
	    transform: translateY(-50%);
	    width: 100%;
	    z-index: 100;
	    visibility: hidden;
	    transition: all 0.3s ease-in-out;
	    opacity: 0;
	}
	.Courses-area .single-courses figure figcaption div a {
	    background: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    border-radius: 0;
	    color: #333333;
	    display: inline-block;
	    font-size: 15px;
	    padding: 10px 30px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.Courses-area .single-courses:hover figcaption {
	    visibility: visible;
	    top: 50%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.Courses-area .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.Courses-area .single-courses .courses-content-box {
	    padding: 25px;
	}
	.Courses-area .single-courses .courses-content-box .courses-content h3 {
	    margin: 0;
	}
	.Courses-area .single-courses .courses-content-box .courses-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.Courses-area .single-courses .courses-content-box .courses-content ul {
	    margin: 0;
	    padding-bottom: 15px;
	    float: left;
	}
	.Courses-area .single-courses .courses-content-box .courses-content ul li {
	    display: table-cell;
	    font-size: 14px;
	    color: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	}
	.Courses-area .single-courses .courses-content-box .courses-content-bottom {
		margin-top: 41px;
	}
	.Courses-area .single-courses .courses-content-box .courses-content span{
		float: right;
		font-weight: 600;
		color: #666;
	}
	.Courses-area .single-courses .courses-content-box .courses-content-bottom ul {
	    border-top: 1px solid #ebebeb;
	    padding-top: 15px;
	    margin-bottom: 0;
	    text-align: right;
	}
	.Courses-area .single-courses .courses-content-box .courses-content-bottom ul li {
	    display: inline-block;
	    text-align: right;
	    color: #b2b2b2;
	    font-size: 15px;
	    padding-left: 10px;
	}
	.Courses-area .single-courses .courses-content-box .courses-content-bottom ul li:first-child {
	    color: #b2b2b2;
	    float: left;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    font-weight: 600;
	    padding-left: 0px;
	}
	.Courses-area .single-courses .courses-content-box .courses-content-bottom ul li:nth-child(2) {
	    color: #b2b2b2;
	    float: left;
	}
	.Courses-area .single-courses:hover .courses-content-box .courses-content h3 a {
	    transition: all 0.3s ease-in-out;
	}
	.Courses-area .single-courses:hover figure::before {
	    visibility: visible;
	    height: 100%;
	    left: 0;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	/*=======[ Indec Video Section Style ]========*/
	
	.video-main-area {
	    background: transparent url("../images/index/pattern.jpg") no-repeat center center/cover;
	    padding: 255px 0;
	}
	.video-main-area .video-play-btn {
	    margin-bottom: 40px;
	    text-align: center;
	    z-index: 1;
	}
	.video-main-area .video-play-btn .video-iframe {
	    background: #ec7a11;
	    border-radius: 50%;
	    color: #ffffff;
	    display: inline-block;
	    font-size: 55px;
	    line-height: 100px;
	    text-align: center;
	    width: 100px;
	    outline: none;
	}
	.video-main-area .video-play-btn .video-iframe i {
	    height: 50px;
	    padding-left: 10px;
	    width: 50px;
	}
	.video-main-area .video-content h2 {
	    color: #ec7a11;
	    padding-bottom: 0px;
	}
	/*=======[ Index Up Comming Events ]========*/
	
	.events-area {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.events-area .events-full-box {
	    margin-bottom: 50px;
	}
	.events-area .events-full-box:last-child {
	    margin-bottom: 0px;
	}
	.events-area .events-single-box {
	    min-height: 280px;
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.events-area .events-single-box img {
	    width: 100%;
	    height: 283px;
	}
	.events-area .events-single-box:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.events-area .events-single-box:hover .event-info h3,
	.events-area .events-single-box:hover a {
	    transition: all 0.3s ease-in-out;
	}
	.events-area .event-info {
	    padding: 45px;
	}
	.events-area .event-info h3 {
	    transition: all 0.3s ease-in-out;
	    padding-bottom: 15px;
	}
	.events-area .event-info .events-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 25px;
	}
	.events-area .event-info .events-time span .event-icon {
	    padding-right: 2px;
	}
	.events-area .event-info p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	    padding-bottom: 15px;
	}
	.events-area .event-info a {
	    color: #666;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.events-area .event-info a .events-btn-icon {
	    padding-left: 5px;
	}
	/*=======[ Index Counter Area Style ]========*/
	
	.counter-area {
	    background: transparent url("../images/index/counter-bg.jpg") no-repeat center center/cover;
	    min-height: 500px;
	    position: relative;
	    padding: 100px 0;
	}
	.counter-area:before {
	    background: rgba(254, 199, 34, 0.1);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    z-index: 0;
	}
	.counter-area:after {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    z-index: 1;
	}
	.counter-area .counters {
	    background: transparent url("../images/index/counter-shape-bg.png") no-repeat center center/cover;
	    text-align: center;
	    z-index: 2;
	    color: #ffffff;
	}
	.counter-area .counters .counters-item {
	    padding: 40px 0;
	}
	.counter-area .counters .counters-item .counter-box img {
	    padding-bottom: 15px;
	}
	.counter-area .counters .counters-item .counter-box .counter {
	    color: #ffffff;
	    font-size: 35px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	}
	.counter-area .counters .counters-item .counter-box span {
	    color: #ffffff;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    text-transform: uppercase;
	}
	.counter-area .counter-text-bottom {
	    background: #ffffff;
	    float: right;
	    margin-top: 50px;
	    padding: 0;
	    text-align: center;
	    z-index: 2;
	    z-index: 2;
	}
	.counter-area .counter-text-bottom .counter-text-box .counter-text {
	    padding: 60px 46px;
	    margin: 0;
	}
	.counter-area .counter-text-bottom .counter-text-box .counter-text p {
	    margin: 0;
	    font-size: 18px;
	    font-family: 'Montserrat', sans-serif;
	    line-height: 26px;
	}
	.counter-area .counter-text-bottom .counter-text-box .counter-btn {
	    background: #ec7a11;
	    padding: 25px 0;
	}
	.counter-area .counter-text-bottom .counter-text-box .counter-btn a {
	    color: #333333;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 25px;
	}
	/*=======[ Index Publication Area Style ]========*/
	
	.publication-area {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.publication-area .publication-single-item {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.publication-area .publication-single-item:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.publication-area .publication-single-item:hover .publication-text h3 a {
	    color: #ec7a11;
	}
	.publication-area .publication-single-item:hover .publication-text ul li a {
	    background: #ec7a11;
	    border: 1px solid #ec7a11;
	}
	.publication-area .publication-single-item img {
	    min-height: 400px;
	    width: 100%;
	}
	.publication-area .publication-single-item .publication-text {
	    padding: 23px 25px;
	}
	.publication-area .publication-single-item .publication-text h3 a {
	    color: #333333;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.publication-area .publication-single-item .publication-text ul li {
	    display: inline-block;
	    color: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    font-weight: 600;
	}
	.publication-area .publication-single-item .publication-text ul li:last-child {
	    float: right;
	}
	.publication-area .publication-single-item .publication-text ul li a {
	    color: #333333;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 10px;
	    font-weight: 600;
	    text-transform: uppercase;
	    text-decoration: none;
	    border: 1px solid #333333;
	    padding: 5px 15px;
	    transition: all 0.3s ease-in-out;
	}
	/*=======[Index News Area Style ]========*/
	
	.news-area {
	    background: transparent url("../images/index/news-bg.jpg") no-repeat center center/cover;
	    min-height: 500px;
	    position: relative;
	    padding: 100px 0;
	}
	.news-area:before {
	    background: rgba(254, 199, 34, 0.2);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	}
	.news-area:after {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    z-index: 1;
	}
	.news-area .section-header {
	    position: relative;
	    z-index: 2;
	}
	.news-area .section-header:before {
	    border-bottom: 1px solid #ffffff;
	}
	.news-area .section-header h2 {
	    color: #ffffff;
	}
	.news-area .news-single-box {
	    overflow: hidden;
	    z-index: 2;
	    position: relative;
	    background: #ffffff;
	}
	.news-area .news-single-box:hover .news-content h3 a {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.news-area .news-single-box:hover .news-img-box img {
	    transform: scale(1.1);
	    transition: all 0.3s ease-in-out;
	}
	.news-area .news-single-box .news-img-box {
	    overflow: hidden;
	    height: auto;
	}
	.news-area .news-single-box .news-img-box img {
	    width: 100%;
	    height: auto;
	    transition: all 0.3s ease-in-out;
	}
	.news-area .news-single-box .news-content {
	    padding: 20px 23px 30px;
	}
	.news-area .news-single-box .news-content h3 {
	    margin: 0;
	    padding-bottom: 10px;
	}
	.news-area .news-single-box .news-content h3 a {
	    margin: 0;
	    color: #333333;
	    font-size: 18px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	    transition: all 0.3s ease-in-out;
	}
	.news-area .news-single-box .news-content .news-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 25px;
	}
	.news-area .news-single-box .news-content .news-time span .event-icon {
	    padding-right: 2px;
	}
	.news-area .news-single-box .news-content p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	}
	.news-area .news-single-box .news-content a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 16px;
	    transition: all 0.3s ease-in-out;
	}
	.news-area .news-single-box .news-content a .events-btn-icon {
	    padding-left: 5px;
	}
	/*=======[Index Students Say Area Style ]========*/
	
	.students-say-area {
	    background: rgb(42, 56, 165);
	    padding: 100px 0;
	    min-height: 500px;
	}
	.students-say-area .section-header h2 {
		margin-bottom: 20px;
		color: #fff;
	}
	.students-say-area .img-full-box {
	    margin-left: 100px;
	}
	.students-say-area .carousel-images .slick-slide.slick-current.slick-active.slick-center {
	    transform: scale(1.7);
	}
	.students-say-area .carousel-images .slick-slide {
	    margin: 35px 0;
	    padding: 2px;
	}
	.students-say-area .carousel-text {
	    text-align: center;
	}
	.students-say-area .carousel-text .single-box {
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 400;
	    color: #666666;
	    font-size: 15px;
	    margin-top: 50px;
	    text-align: center;
	    outline: none;
	}
	.students-say-area .carousel-text .single-box p {
		margin: 0 auto;
		color: #fff;
	    width: 80%;
	    position: relative;
	}
	.students-say-area .carousel-text .single-box p::before {
	    background: url("../images/index/quotation-01.png") no-repeat;
	    content: "";
	    height: 80px;
	    position: absolute;
	    top: -30px;
	    width: 80px;
	    left: -65px;
	}
	.students-say-area .carousel-text .single-box p::after {
	    background: url("../images/index/quotation-02.png") no-repeat;
	    content: "";
	    height: 80px;
	    position: absolute;
	    right: -40px;
	    width: 80px;
	}
	.students-say-area .carousel-text .single-box .rank-icons {
	    margin: 0;
	    padding: 20px;
	}
	.students-say-area .carousel-text .single-box .rank-icons li {
	    display: inline-block;
	    color: #ec7a11;
	}
	.students-say-area .carousel-text .single-box h3 {
		padding-bottom: 6px;
		color: #fff;
	    font-family: 'Montserrat', sans-serif;
	}
	.students-say-area .carousel-text .single-box span {
		font-size: 15px;
		color: #fff;
	}
	.students-say-area .slick-prev {
	    background: url("../images/index/arror-02-left.png") center center no-repeat;
	}
	.students-say-area .slick-next {
	    background: url("../images/index/arror-02-right.png") center center no-repeat;
	}
	/*=======[Index Instraction Area Style ]========*/
	
	.instraction-area {
	    background: transparent url("../images/index/instraction-bg.jpg") no-repeat center center/cover;
	    position: relative;
	}
	.instraction-area:before {
	    background: rgba(73, 114, 178, 0.75);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	}
	.instraction-area .inspiration-full-box {
	    padding: 70px 0;
	}
	.instraction-area .inspiration-full-box .section-header-l {
	    padding-bottom: 0px;
	}
	.instraction-area .inspiration-full-box .section-header-l:before {
	    display: none;
	}
	.instraction-area .inspiration-full-box .section-header-l h2 {
	    padding-bottom: 0px;
	    margin-bottom: 20px;
	}
	.instraction-area .inspiration-full-box .section-header-l p {
	    color: #333333;
	    font-size: 18px;
	    font-family: 'Montserrat', sans-serif;
	    margin: 0;
	    padding: 0px;
	    text-align: left;
	}
	.instraction-area .inspiration-full-box .instraction-btn {
	    margin: 12px 0;
	    width: 208px;
	    height: 55px;
	    background: #292929;
	    text-align: center;
	}
	.instraction-area .inspiration-full-box .instraction-btn a {
	    line-height: 55px;
	    color: #ffffff;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	/*=======[Index Footer Area Style ]========*/
	
	footer {
	    background: transparent url("../images/index/footer-bg.jpg") no-repeat center center/cover;
	    min-height: 150px;
	    padding-top: 100px;
	}
	footer .footer-content-box {
	    border-bottom: 1px solid #a0a0a0;
	    padding: 0 0 50px 0;
	    color: #a0a0a0;
	}
	footer .footer-content-box h3 {
	    color: #fff;
	}
	footer .footer-content-box h3 span {
	    color: #ec7a11;
	    font-size: 28px;
	}
	footer .footer-content-box p {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 14px;
	    padding-bottom: 10px;
	    color: #a0a0a0;
	}
	footer .footer-content-box ul li {
	    padding-bottom: 12px;
	}
	footer .footer-content-box ul li:last-child {
	    padding-bottom: 0px;
	}
	footer .footer-content-box ul li a {
	    color: #9f9f9f;
	    text-decoration: none;
	    transition: all 0.1s ease-in-out;
	}
	footer .footer-content-box ul li a:hover {
	    color: #ec7a11;
	    transition: all 0.1s ease-in-out;
	    padding-left: 3px;
	}
	footer .footer-content-box ul span {
	    padding-right: 6px;
	}
	footer .footer-content-box form .form-group input {
	    background: rgba(73, 73, 73, 0.65);
	    height: 45px;
	    font-size: 16px;
	    border: none;
	    color: #a0a0a0;
	    padding-left: 15px;
	    width: 100%;
	    margin-bottom: 15px;
	    outline: none;
	}
	footer .footer-content-box form .form-group input:focus {
	    outline: none;
	}
	footer .footer-content-box form .form-group input::-moz-placeholder {
	    color: #a0a0a0;
	    font-size: 13px;
	}
	footer .footer-content-box form .form-group button {
	    background: #ec7a11;
	    border: medium none;
	    color: #FFF;
	    outline: medium none;
	    padding: 8px 15px;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 13px;
	}
	footer .footer-bottom .footer-bottom-inner {
	    padding: 25px 0;
	}
	footer .footer-bottom .footer-bottom-inner .footer-no-padding {
	    padding: 0;
	}
	footer .footer-bottom .footer-bottom-inner p {
	    margin: 0;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 400;
	    font-size: 14px;
	    color: #9f9f9f;
	}
	footer .footer-bottom .footer-bottom-inner p span {
	    font-weight: 600;
	    color: #ec7a11;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu {
	    margin: 0;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu span a {
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    color: #9f9f9f;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu li {
	    display: inline-block;
	    margin-right: 15px;
	    color: #9f9f9f;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu li:last-child {
	    margin-right: 0px;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu li a {
	    font-size: 14px;
	    color: #9f9f9f;
	    text-decoretion: none;
	    transition: all 0.1s ease-in-out;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu li a:hover .facebook {
	    color: #3b59a1;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu li a:hover .twitter {
	    color: #1da1f2;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu li a:hover .instagram {
	    color: #f56040;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu li a:hover .google {
	    color: #dd4b39;
	}
	footer .footer-bottom .footer-bottom-inner .footer-menu li a:hover .skype {
	    color: #1DA1FF;
	}
	/*====================================================================================================
										===[ Index  Version 02 ]===
=====================================================================================================*/
	
	.home_version_02 {
	    overflow-x: hidden;
	    /*=======[ Index 02 Welcome Area Style ]========*/
	    /*=======[ Index 02 Rgister Area Style ]========*/
	    /*=======[ Index 02 Courses Area Style ]========*/
	    /*=======[ Index 02 Information Area Style ]========*/
	    /*=======[Index 02 Up Comming Events ]========*/
	    /*=======[ Index 02 Student Parent Say Style ]========*/
	    /*=======[Index 02 Teachers Area Style ]========*/
	    /*=======[ Index 02 Our blog Area Style ]========*/
	    /*=======[Index 02 Instraction Area Style ]========*/
	    /*=======[Index Footer Area Style ]========*/
	}
	.home_version_02 h2 {
	    padding-bottom: 15px;
	}
	.home_version_02 .Welcome-area {
	    padding: 100px 0;
	}
	.home_version_02 .Welcome-area .wel-text-box {
	    text-align: center;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-icon {
	    background-color: #ec7a11;
	    border-radius: 100%;
	    height: 120px;
	    margin: 0 auto;
	    position: relative;
	    width: 120px;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-icon:before {
	    border: 8px solid rgba(254, 199, 34, 0.2);
	    border-radius: 100%;
	    content: "";
	    height: 114%;
	    left: -8px;
	    position: absolute;
	    top: -8px;
	    width: 114%;
	    z-index: 2;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-icon img {
	    height: 60px;
	    margin-top: 20px;
	    width: 60px;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-text {
	    margin-top: -60px;
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    padding: 100px 25px 40px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-text:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-text h3 {
	    font-size: 20px;
	    padding-bottom: 15px;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-text p {
	    padding-bottom: 25px;
	    margin: 0;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-text a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .Welcome-area .wel-text-box .wel-text a i {
	    padding-left: 5px;
	}

	.home_version_02 .register-area {
	    background: transparent url("../images/index-02/register-bg.jpg") no-repeat center center/cover;
	    min-height: 500px;
	    position: relative;
	    padding: 100px 0;
	}
	.home_version_02 .register-area:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    z-index: 0;
	}
	.home_version_02 .register-area .form-full-box {
	    background: #ffffff;
	    margin: 0 60px 0 15px;
	}
	.home_version_02 .register-area .form-full-box .header-box-top {
	    text-align: center;
	    padding: 45px 25px;
	}
	.home_version_02 .register-area .form-full-box .header-box-top .section-header-box {
	    margin-bottom: 35px;
	}
	.home_version_02 .register-area .form-full-box .header-box-top .section-header-box .section-header h2 {
	    font-size: 30px;
	    padding-bottom: 0;
	}
	.home_version_02 .register-area .form-full-box .header-box-top p {
	    margin: 0;
	}
	.home_version_02 .register-area .form-full-box .register-form {
	    padding: 0 50px 35px;
	}
	.home_version_02 .register-area .form-full-box .register-form .form-group {
	    margin-bottom: 15px;
	}
	.home_version_02 .register-area .form-full-box .register-form .form-group input {
	    background: transparent;
	    border-color: #bababa;
	    border-radius: 0px;
	    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0);
	    height: 40px;
	    padding: 10px;
	    transition: all 0.5s ease 0s;
	    width: 100%;
	}
	.home_version_02 .register-area .form-full-box .register-form .form-group input:focus {
	    border-color: #ec7a11;
	    outline: none;
	    box-shadow: none;
	}
	.home_version_02 .register-area .form-full-box .register-form .register-btn-box {
	    margin-top: 5px;
	}
	.home_version_02 .register-area .form-full-box .register-form .register-btn-box .register-btn {
	    background: #ec7a11;
	    width: 100%;
	    color: #fff;
	    height: 40px;
	    font-size: 15px;
	    border: none;
	    border-radius: 0px;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	}
	.home_version_02 .register-area .form-full-box .register-form .register-btn-box .register-btn:hover {
	    background: #232323;
	    color: #fff;
	}
	.home_version_02 .register-area .form-content {
	    margin-top: 150px;
	    padding-left: 55px;
	}
	.home_version_02 .register-area .form-content .section-header:before {
	    border-bottom: 1px solid #ffffff;
	}
	.home_version_02 .register-area .form-content .section-header h2 {
	    color: #ffffff;
	    text-transform: none;
	    font-size: 40px;
	    padding-bottom: 0;
	}
	.home_version_02 .register-area .form-content p {
	    color: #fff;
	    margin: 0;
	}
	.home_version_02 .Courses-area-02 {
	    background: #f9f9f9;
	    padding: 100px 0;
	}
	.home_version_02 .Courses-area-02 .courses-r-margin-bottom {
	    margin-bottom: 30px;
	}
	.home_version_02 .Courses-area-02 .courses-r-margin-bottom:last-child {
	    margin-bottom: 0;
	}
	.home_version_02 .Courses-area-02 .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .Courses-area-02 .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .Courses-area-02 .single-courses figure {
	    position: relative;
	}
	.home_version_02 .Courses-area-02 .single-courses figure .figure-img {
	    position: relative;
	    width: auto;
	    overflow: hidden;
	}
	.home_version_02 .Courses-area-02 .single-courses figure .figure-img img {
	    width: 100%;
	    height: 100%;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .Courses-area-02 .single-courses figure .figure-img img:hover {
	    transform: scale(1.1);
	}
	.home_version_02 .Courses-area-02 .single-courses figure .figure-img .sale-banner {
	    position: absolute;
	    content: "";
	    top: -15px;
	    left: -55px;
	    padding: 30px 50px 10px;
	    z-index: 100;
	    background: #ec7a11;
	    transform: rotate(-45deg);
	    text-transform: uppercase;
	    color: #ffffff;
	    font-weight: 700;
	    font-family: 'Montserrat', sans-serif;
	}
	.home_version_02 .Courses-area-02 .single-courses figure figcaption ul {
	    margin: 0;
	}
	.home_version_02 .Courses-area-02 .single-courses figure figcaption ul li {
	    display: inline-block;
	    text-align: left;
	    font-family: 'Montserrat', sans-serif;
	    color: #ec7a11;
	}
	.home_version_02 .Courses-area-02 .single-courses figure figcaption ul li:last-child {
	    float: right;
	}
	.home_version_02 .Courses-area-02 .single-courses figure figcaption ul .courses-free {
	    position: absolute;
	    bottom: 0;
	    z-index: 100;
	    left: 0;
	    background: #ffffff;
	    padding: 7px 15px;
	    text-transform: uppercase;
	    line-height: 30px;
	    font-size: 18px;
	}
	.home_version_02 .Courses-area-02 .single-courses figure figcaption ul .courses-free span {
	    font-size: 14px;
	    text-decoration: line-through;
	    font-style: italic;
	    color: #666666;
	}
	.home_version_02 .Courses-area-02 .single-courses figure figcaption ul .rating {
	    position: absolute;
	    color: #ec7a11;
	    bottom: 0;
	    z-index: 100;
	    right: 0;
	    padding: 0px 15px 13px;
	    font-size: 12px;
	}
	.home_version_02 .Courses-area-02 .single-courses .courses-content-box {
	    padding: 20px;
	}
	.home_version_02 .Courses-area-02 .single-courses .courses-content-box .courses-content h3 {
	    margin: 0;
	}
	.home_version_02 .Courses-area-02 .single-courses .courses-content-box .courses-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-weight: 700;
	    font-size: 15px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .information-area {
	    background: transparent url("../images/index-02/info_bg_v2.jpg") no-repeat center center/cover;
	    padding: 165px 0;
	}
	.home_version_02 .information-area .info-content-box {
	    text-align: center;
	    color: #ffffff;
	}
	.home_version_02 .information-area .info-content-box h2 {
	    color: #ffffff;
	    font-size: 30px;
	    padding-bottom: 30px;
	}
	.home_version_02 .information-area .info-content-box p {
	    color: #ffffff;
	    font-size: 18px;
	    width: 94%;
	    line-height: 26px;
	}
	.home_version_02 .information-area .info-content-box .info-btn {
	    padding: 18px 0;
	    margin-top: 50px;
	}
	.home_version_02 .information-area .info-content-box .info-btn .information-btn {
	    background: #ec7a11;
	    color: #333333;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    padding: 15px 50px;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	.home_version_02 .events-area {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.home_version_02 .events-area .event-body-content {
	    margin-bottom: 50px;
	}
	.home_version_02 .events-area .events-single-box {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .events-area .events-single-box img {
	    width: 100%;
	    height: auto;
	}
	.home_version_02 .events-area .events-single-box:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .events-area .events-single-box:hover .event-info h3,
	.home_version_02 .events-area .events-single-box:hover a {
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .events-area .event-info {
	    padding: 30px 35px;
	}
	.home_version_02 .events-area .event-info h3 {
	    transition: all 0.3s ease-in-out;
	    padding-bottom: 15px;
	}
	.home_version_02 .events-area .event-info .events-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 25px;
	}
	.home_version_02 .events-area .event-info .events-time span .event-icon {
	    padding-right: 2px;
	}
	.home_version_02 .events-area .event-info p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	    padding-bottom: 15px;
	}
	.home_version_02 .events-area .event-info a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .events-area .event-info a .events-btn-icon {
	    padding-left: 5px;
	    margin: 10px 0;
	}
	.home_version_02 .events-area .event-bottom-btn {
	    text-align: center;
	    margin: 18px 0;
	}
	.home_version_02 .events-area .event-bottom-btn .view-more-item {
	    background: #ec7a11;
	    color: #333333;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    padding: 18px 50px;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	.home_version_02 .stu-parent-say-area {
	    background: transparent url("../images/index-02/parent-say-bg.jpg") no-repeat center center/cover;
	    min-height: 500px;
	    position: relative;
	    padding: 100px 0;
	}
	.home_version_02 .stu-parent-say-area:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	}
	.home_version_02 .stu-parent-say-area .section-header h2 {
	    color: #fff;
	}
	.home_version_02 .stu-parent-say-area .section-header:before {
	    border-bottom: 1px solid #ffffff;
	}
	.home_version_02 .stu-parent-say-area .section-header p {
	    color: #fff;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item {
	    background: #ffffff;
	    margin: 0 15px;
	    padding: 80px 40px 25px;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .content-box p {
	    position: relative;
	    margin: 0;
	    z-index: 1;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .content-box p:before {
	    position: absolute;
	    content: "\f10d";
	    font-family: FontAwesome;
	    left: -10px;
	    top: -15px;
	    font-size: 100px;
	    z-index: -1;
	    color: #000000;
	    opacity: 0.10;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .content-box .rating-box {
	    margin: 0;
	    padding: 15px;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .content-box .rating-box li {
	    display: inline-block;
	    color: #ec7a11;
	    font-size: 16px;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .images-box ul li {
	    display: inline-block;
	    vertical-align: middle;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .images-box ul li:first-child {
	    height: 55px;
	    width: 55px;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .images-box ul li:first-child img {
	    height: 100%;
	    width: 100%;
	    border-radius: 100%;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .images-box ul li:last-child {
	    padding-left: 15px;
	}
	.home_version_02 .stu-parent-say-area .screen-content-box .single--item .images-box ul li:last-child h3 {
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    margin: 0;
	    padding: 0;
	}
	.home_version_02 .stu-parent-say-area .owl-prev,
	.home_version_02 .stu-parent-say-area .owl-next {
	    border: 1px solid #fff;
	    border-radius: 100px;
	    color: #fff;
	    display: inline-block;
	    font-size: 15px;
	    height: 60px;
	    margin-top: -35px;
	    position: absolute;
	    text-align: center;
	    top: 40%;
	    transition: all 0.3s ease 0s;
	    width: 60px;
	}
	.home_version_02 .stu-parent-say-area .owl-prev:hover,
	.home_version_02 .stu-parent-say-area .owl-next:hover {
	    border: 1px solid #ec7a11;
	}
	.home_version_02 .stu-parent-say-area .owl-prev:hover .fa-angle-right,
	.home_version_02 .stu-parent-say-area .owl-next:hover .fa-angle-right {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .stu-parent-say-area .owl-prev:hover .fa-angle-left,
	.home_version_02 .stu-parent-say-area .owl-next:hover .fa-angle-left {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .stu-parent-say-area .owl-prev {
	    left: -80px;
	}
	.home_version_02 .stu-parent-say-area .owl-next {
	    right: -80px;
	}
	.home_version_02 .stu-parent-say-area .fa-angle-right,
	.home_version_02 .stu-parent-say-area .fa-angle-left {
	    transition: all 0.3s ease-in-out;
	    font-size: 25px;
	    color: #fff;
	    line-height: 50px;
	}
	.home_version_02 .teachers-area {
	    padding: 100px 0;
	}
	.home_version_02 .teachers-area .teacher-body {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .teachers-area .teacher-body:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .teachers-area .teacher-body img {
	    width: 100%;
	    min-height: 290px;
	}
	.home_version_02 .teachers-area .teacher-body .teachars-info {
	    text-align: center;
	    padding: 25px 20px 12px;
	}
	.home_version_02 .teachers-area .teacher-body .teachars-info h3 {
	    padding: 0;
	    font-size: 20px;
	    margin-bottom: 4px;
	}
	.home_version_02 .teachers-area .teacher-body .teachars-info span {
	    font-size: 14px;
	    font-family: 'Montserrat', sans-serif;
	    text-transform: uppercase;
	    color: #666;
	}
	.home_version_02 .teachers-area .teacher-body .teachars-info ul {
	    margin-top: 20px;
	}
	.home_version_02 .teachers-area .teacher-body .teachars-info ul li {
	    margin: 0 5px;
	    display: inline-block;
	}
	.home_version_02 .teachers-area .teacher-body .teachars-info ul li .teacher-icon {
	    background: #f9f9f9;
	    border-radius: 100%;
	    color: #a7a7a7;
	    height: 30px;
	    padding: 7px;
	    width: 30px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .teachers-area .teacher-body .teachars-info ul li .teacher-icon:hover {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    background: #ec7a11;
	    color: #ffffff;
	}
	.home_version_02 .blog-area {
	    background: #f9f9f9;
	    min-height: 500px;
	    padding: 100px 0;
	}
	.home_version_02 .blog-area .blog-single-box {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    z-index: 2;
	    background: #ffffff;
	}
	.home_version_02 .blog-area .blog-single-box:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box:hover .blog-content h3 a {
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box:hover .blog-content .content-bottom .first-item a {
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box .img-box {
	    overflow: hidden;
	}
	.home_version_02 .blog-area .blog-single-box .img-box img {
	    width: 100%;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box:hover img {
	    transform: scale(1.1);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content {
	    padding: 20px 23px 30px;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content h3 {
	    margin: 0;
	    padding-bottom: 10px;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content h3 a {
	    margin: 0;
	    color: #333333;
	    font-size: 18px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .blog-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 25px;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .blog-time span .event-icon {
	    padding-right: 2px;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content p {
	    margin: 0;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom p {
	    padding: 15px 0;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul {
	    margin-bottom: 0;
	    text-align: right;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul li {
	    display: inline-block;
	    text-align: right;
	    color: #b2b2b2;
	    font-size: 16px;
	    padding-left: 10px;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul li:first-child {
	    float: left;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    font-weight: 600;
	    padding-left: 0px;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul li:first-child:hover .blog-btn-icon {
	    padding-left: 20px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul li:first-child .blog-btn-icon {
	    padding-left: 5px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul li a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul li a:hover .facebook {
	    color: #3b59a1;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul li a:hover .twitter {
	    color: #1da1f2;
	}
	.home_version_02 .blog-area .blog-single-box .blog-content .content-bottom ul li a:hover .youtube {
	    color: #f56040;
	}
	.home_version_02 .instraction-area {
	    background: transparent url("../images/index-02/instraction-bg.jpg") no-repeat center center/cover;
	    position: relative;
	}
	.home_version_02 .instraction-area:before {
	    background: rgba(254, 199, 34, 0.75);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	}
	.home_version_02 footer {
	    background: transparent url("../images/index-02/footer-bg.jpg") no-repeat center center/cover;
	    min-height: 150px;
	    padding-top: 100px;
	}
	/*==============================================================================================================
										     ===[ Index  Version 03 ]===
================================================================================================================*/
	
	.home_version_03 {
	    /*=======[ Index 03 Default Style ]========*/
	    /*=======[ Index 03 Header Style ]========*/
	    /*=======[ Index 03 Wellcome Area ]========*/
	    /*=======[ Index 03 Search Courses Area ]========*/
	    /*=======[ Index 03 Courses Area ]========*/
	    /*=======[ Index 03 Achievment Area ]========*/
	    /*=======[ Index 03 Gallery Area ]========*/
	    /*=======[ Index 03 Student Count Area Style ]========*/
	    /*=======[Index 03 Blog Area Style ]========*/
	    /*=======[Index 03 Partners Area Style ]========*/
	    /*=======[Index 03 Footer Area Style ]========*/
	}
	.home_version_03 .owl-prev {
	    right: 55px;
	}
	.home_version_03 .owl-prev .fa-angle-left {
	    color: #333333;
	    font-size: 18px;
	    line-height: 26px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .owl-next {
	    right: 18px;
	}
	.home_version_03 .owl-next .fa-angle-right {
	    color: #333333;
	    font-size: 18px;
	    line-height: 26px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 header .header-body {
	    background: url("../images/index-03/banner-bg.jpg") no-repeat center center;
	    min-height: 810px;
	    background-size: cover;
	    position: relative;
	}
	.home_version_03 header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.home_version_03 header .header-body .intro-text {
	    text-align: center;
	    margin-top: 200px;
	}
	.home_version_03 header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 50px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.home_version_03 header .header-body .intro-text p {
	    font-size: 18px;
	    line-height: 27px;
	    color: #ffffff;
	    margin: 0;
	    padding: 25px 0 50px;
	}
	.home_version_03 header .header-body .intro-text .el-btn-regular {
	    background: transparent;
	    border: 1px solid #ffffff;
	    border-radius: 0px;
	    color: #ffffff;
	    text-decoration: none;
	    display: inline-block;
	    font-size: 15px;
	    font-weight: 500;
	    padding: 15px 25px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    text-transform: uppercase;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 header .header-body .intro-text .el-btn-regular:hover {
	    background: #ec7a11;
	    border: 1px solid #ec7a11;
	    color: #333333;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 header .header-body .intro-text .slider-btn-left {
	    margin-right: 10px;
	    color: #333333;
	    border: 1px solid #ec7a11;
	    background: #ec7a11;
	}
	.home_version_03 header .header-body .mouse-icon-box {
	    margin: 150px auto 0;
	}
	.home_version_03 header .header-body .mouse-icon-box .mouse-icon {
	    position: relative;
	    border: 2px solid #fff;
	    border-radius: 16px;
	    margin-left: 28px;
	    padding: 15px 12px;
	    z-index: 10;
	}
	.home_version_03 header .header-body .mouse-icon-box .mouse-icon:before {
	    position: absolute;
	    border-radius: 10px;
	    background: #fff;
	    width: 2px;
	    height: 10px;
	    top: 8px;
	    left: 0;
	    margin-left: 12px;
	    margin-top: 10;
	    content: "";
	    transition: all 0.2s ease-in-out;
	}
	.home_version_03 header .header-body .mouse-icon-box:hover .mouse-icon::before {
	    background: #ec7a11;
	    top: 28px;
	    transition: all 0.2s ease-in-out;
	}
	.home_version_03 .Welcome-area {
	    background: #ffffff;
	    padding: 100px 0;
	    /*=======[  Wellcome > Event Area ]========*/
	}
	.home_version_03 .Welcome-area h2 {
	    margin: 0;
	    text-align: left;
	    text-transform: none;
	    font-size: 35px;
	    position: relative;
	}
	.home_version_03 .Welcome-area .wel-shap {
	    position: relative;
	}
	.home_version_03 .Welcome-area .wel-shap:before {
	    background: url("../images/index-03/wel_shap.png") no-repeat center center;
	    content: "";
	    height: 100%;
	    left: 45%;
	    position: absolute;
	    width: 100%;
	}
	.home_version_03 .Welcome-area .wel-shap .img-full-box .images-box {
	    min-height: 290px img;
	    min-height-height: 100%;
	    min-height-width: 100%;
	}
	.home_version_03 .Welcome-area .wel-shap .content-full-box {
	    padding-right: 70px;
	}
	.home_version_03 .Welcome-area .wel-shap .content-full-box .wel-content-box p {
	    font-size: 14px;
	    margin: 0;
	    padding-bottom: 25px;
	}
	.home_version_03 .Welcome-area .wel-shap .content-full-box .wel-content-box p:first-child {
	    margin-top: -8px;
	    padding-bottom: 25px;
	}
	.home_version_03 .Welcome-area .wel-shap .content-full-box .wel-content-box .readmore-btn {
	    color: #666;
	    font-size: 14px;
	    text-transform: uppercase;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    position: relative;
	    padding-right: 15px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Welcome-area .wel-shap .content-full-box .wel-content-box .readmore-btn .readmore-btn-icon {
	    padding-left: 5px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Welcome-area .wel-shap .content-full-box .wel-content-box .readmore-btn:hover {
	    color: #ec7a11;
	}
	.home_version_03 .Welcome-area .wel-shap .content-full-box .wel-content-box .readmore-btn:hover .readmore-btn-icon {
	    padding-left: 10px;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding {
	    margin-bottom: 20px;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding:last-child {
	    margin-bottom: 50px;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-img {
	    background: url("../images/index-03/event-time-bg.jpg") no-repeat center center;
	    background-position: center;
	    position: relative;
	    height: 65px;
	    width: 65px;
	    z-index: 1;
	    padding: 0;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-img:before {
	    background: rgba(254, 199, 34, 0.9);
	    content: "";
	    height: 100%;
	    left: 0;
	    position: absolute;
	    width: 100%;
	    top: 0;
	    z-index: -1;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-img ul li {
	    text-align: center;
	    font-size: 15px;
	    text-transform: uppercase;
	    font-weight: 600;
	    font-family: 'Montserrat', sans-serif;
	    line-height: 16px;
	    padding-top: 5px;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-img ul li:first-child {
	    font-size: 25px;
	    font-weight: 700;
	    color: #333333;
	    margin-top: 8px;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-content h3 {
	    margin-top: 8px;
	    padding-bottom: 2px;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-content h3 a {
	    padding-bottom: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    text-transform: none;
	    font-size: 18px;
	    color: #333333;
	    text-decoration: none;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-content .event-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 18px;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-content .event-time span:last-child {
	    margin-right: 0;
	}
	.home_version_03 .Welcome-area .event-box .event-box-padding .event-content .event-time span .event-icon {
	    padding-right: 2px;
	}
	.home_version_03 .Welcome-area .event-box .readmore-btn-box {
	    margin-top: 20px;
	}
	.home_version_03 .Welcome-area .event-box .readmore-btn-box .readmore-btn {
	    font-size: 14px;
	    text-transform: uppercase;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    position: relative;
	    padding-right: 15px;
	    color: #666;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Welcome-area .event-box .readmore-btn-box .readmore-btn .readmore-btn-icon {
	    padding-left: 5px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Welcome-area .event-box .readmore-btn-box .readmore-btn:hover {
	    color: #ec7a11;
	}
	.home_version_03 .Welcome-area .event-box .readmore-btn-box .readmore-btn:hover .readmore-btn-icon {
	    padding-left: 10px;
	}
	.home_version_03 .search-curses-area {
	    background: url("../images/index-03/scarch-bg.jpg") no-repeat center center/cover;
	    background-position: center;
	    position: relative;
	    z-index: 1;
	    padding: 100px 0;
	}
	.home_version_03 .search-curses-area:before {
	    background: rgba(0, 0, 0, 0.6);
	    content: "";
	    height: 100%;
	    left: 0;
	    position: absolute;
	    width: 100%;
	    top: 0;
	    z-index: -1;
	}
	.home_version_03 .search-curses-area .section-header h2 {
	    color: #ffffff;
	    text-transform: none;
	}
	.home_version_03 .search-curses-area .section-header:before {
	    border-bottom: 1px solid #ffffff;
	}
	.home_version_03 .search-curses-area .search-courses-form .cearch-form .form-group {
	    margin-bottom: 0px;
	}
	.home_version_03 .search-curses-area .search-courses-form .cearch-form .form-group input {
	    background-color: #fff;
	    border-radius: 0px;
	    border: none;
	    width: 100%;
	    color: #a1a1a1;
	    padding-left: 15px;
	    box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) inset;
	    height: 50px;
	    color: #666666;
	}
	.home_version_03 .search-curses-area .search-courses-form .cearch-form .form-group .form-control {
	    -moz-appearance: none;
	    background-color: #fff;
	    background-image: url("../images/index-03/down-arrow.png");
	    background-position: 98% 50%;
	    background-repeat: no-repeat;
	    padding-left: 15px;
	    border-radius: 0px;
	    color: #666;
	    padding-right: 15px;
	    border: none;
	    box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) inset;
	    height: 50px;
	    opacity: 1;
	}
	.home_version_03 .search-curses-area .search-courses-form .cearch-form .scrach-btn-box {
	    text-align: center;
	}
	.home_version_03 .search-curses-area .search-courses-form .cearch-form .scrach-btn-box .scrach-btn {
	    background: #ec7a11;
	    border: none;
	    font-size: 15px;
	    border-radius: 0;
	    color: #333333;
	    font-weight: 600;
	    font-family: 'Montserrat', sans-serif;
	    margin-top: 40px;
	    padding: 15px 40px;
	    text-transform: uppercase;
	}
	.home_version_03 .Courses-area {
	    background: #f9f9f9;
	    padding: 100px 0 60px;
	}
	.home_version_03 .Courses-area .section-header-box {
	    margin-bottom: 0;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	    transition: all 0.3s ease-in-out;
	    margin: 40px 0;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses figure {
	    position: relative;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses figure .figure-img {
	    position: relative;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses figure .figure-img img {
	    width: 100%;
	    height: auto;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses figure .figure-img::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100%;
	    width: 100%;
	    background: rgba(254, 199, 34, 0.1);
	    z-index: 1;
	    content: "";
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses figure figcaption {
	    position: absolute;
	    text-align: center;
	    top: 0;
	    transform: translateY(-50%);
	    width: 100%;
	    z-index: 100;
	    visibility: hidden;
	    transition: all 0.3s ease-in-out;
	    opacity: 0;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses figure figcaption div a {
	    background: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    border-radius: 0;
	    color: #333333;
	    display: inline-block;
	    font-size: 15px;
	    padding: 10px 30px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses figure:before {
	    background: rgba(0, 0, 0, 0.4);
	    content: "";
	    height: 0%;
	    opacity: 0;
	    position: absolute;
	    width: 100%;
	    z-index: 1;
	    visibility: hidden;
	    top: 0;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses:hover figcaption {
	    visibility: visible;
	    top: 50%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.15);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box {
	    padding: 25px 20px;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content h3 {
	    margin: 0;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content ul {
	    margin: 0;
	    padding-bottom: 15px;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content ul li {
	    display: table-cell;
	    font-size: 14px;
	    color: #666666;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content ul li span {
	    text-transform: uppercase;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content ul li:first-child {
	    padding-right: 20px;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .rank-icons ul {
	    display: inline-block;
	    padding: 5px 0;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .rank-icons ul li {
	    display: inline-block;
	    color: #ec7a11;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .rank-icons span {
	    padding-left: 15px;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul {
	    border-top: 1px solid #ebebeb;
	    padding-top: 15px;
	    margin-bottom: 0;
	    text-align: right;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul li {
	    display: inline-block;
	    text-align: right;
	    color: #b2b2b2;
	    font-size: 16px;
	    padding-left: 10px;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul li:first-child {
	    float: left;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    font-weight: 600;
	    padding-left: 0px;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses:hover .courses-content-box .courses-content h3 a {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Courses-area .courses-wrapper .single-courses:hover figure::before {
	    visibility: visible;
	    height: 100%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Courses-area .courses-wrapper .owl-prev,
	.home_version_03 .Courses-area .courses-wrapper .owl-next {
	    border: 1px solid #333333;
	    border-radius: 0px;
	    color: #fff;
	    display: inline-block;
	    font-size: 15px;
	    height: 33px;
	    position: absolute;
	    text-align: center;
	    top: -33px;
	    transition: all 0.3s ease-in-out;
	    width: 33px;
	}
	.home_version_03 .Courses-area .courses-wrapper .owl-prev:hover,
	.home_version_03 .Courses-area .courses-wrapper .owl-next:hover {
	    border: 1px solid #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .Courses-area .courses-wrapper .owl-prev:hover .fa-angle-left,
	.home_version_03 .Courses-area .courses-wrapper .owl-prev:hover .fa-angle-right,
	.home_version_03 .Courses-area .courses-wrapper .owl-next:hover .fa-angle-left,
	.home_version_03 .Courses-area .courses-wrapper .owl-next:hover .fa-angle-right {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .achievment-area {
	    background: url("../images/index-03/achievment-bg.jpg") no-repeat center center/cover;
	    background-position: center;
	    position: relative;
	    z-index: 1;
	    padding: 100px 0;
	}
	.home_version_03 .achievment-area:before {
	    background: rgba(0, 0, 0, 0.6);
	    content: "";
	    height: 100%;
	    left: 0;
	    position: absolute;
	    width: 100%;
	    top: 0;
	    z-index: -1;
	}
	.home_version_03 .achievment-area .section-header h2 {
	    color: #ffffff;
	    text-transform: none;
	}
	.home_version_03 .achievment-area .section-header:before {
	    border-bottom: 1px solid #ffffff;
	}
	.home_version_03 .achievment-area .counters-item {
	    text-align: center;
	    position: relative;
	}
	.home_version_03 .achievment-area .counters-item:before {
	    position: absolute;
	    background: url("../images/index-03/achievment-shap.png") no-repeat center center;
	    content: "";
	    height: 100%;
	    left: 50%;
	    width: 100%;
	    top: 0;
	    z-index: -1;
	}
	.home_version_03 .achievment-area .counters-item:last-child:before {
	    display: none;
	}
	.home_version_03 .achievment-area .counters-item .counter-box img {
	    padding-bottom: 15px;
	}
	.home_version_03 .achievment-area .counters-item .counter-box .counter {
	    color: #ffffff;
	    font-size: 35px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	}
	.home_version_03 .achievment-area .counters-item .counter-box span {
	    color: #ffffff;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 18px;
	    text-transform: uppercase;
	}
	.home_version_03 .gallery-area {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-01 {
	    padding: 0;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 {
	    padding: 0;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item {
	    margin: 3px;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item figure {
	    position: relative;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item figure:before {
	    position: absolute;
	    content: "";
	    width: 100%;
	    height: 100%;
	    background: rgba(254, 199, 34, 0.75);
	    transform: rotate(45deg) scale(0);
	    transition: .5s ease;
	    visibility: hidden;
	    opacity: 0;
	    left: 0;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item figure img {
	    height: 100%;
	    width: 100%;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item figure figcaption {
	    position: absolute;
	    left: 0;
	    top: 45%;
	    width: 100%;
	    text-align: center;
	    transition: all 0.3s ease-in-out;
	    visibility: hidden;
	    opacity: 0;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item figure figcaption ul li {
	    display: inline-block;
	    font-size: 27px;
	    height: 30px;
	    line-height: 26px;
	    margin: 0 5px;
	    width: 30px;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item figure figcaption ul li a {
	    color: #ffffff;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item:hover figure::before {
	    visibility: visible;
	    opacity: 1;
	    transform: rotate(0deg) scale(1);
	    width: 100;
	}
	.home_version_03 .gallery-area .gallery-body-img .gallery-c-02 .gallery-single-item:hover figcaption {
	    visibility: visible;
	    transition: all 0.3s ease-in-out;
	    opacity: 1;
	}
	.home_version_03 .all-stu-count-area {
	    background: transparent url("../images/index-03/all-stu-count-bg.jpg") no-repeat center center/cover;
	    padding: 165px 0;
	    position: relative;
	}
	.home_version_03 .all-stu-count-area:before {
	    background: rgba(0, 0, 0, 0.6);
	    content: "";
	    height: 100%;
	    left: 0;
	    position: absolute;
	    width: 100%;
	    top: 0;
	}
	.home_version_03 .all-stu-count-area .info-content-box {
	    text-align: center;
	    color: #ffffff;
	}
	.home_version_03 .all-stu-count-area .info-content-box h2 {
	    color: #ffffff;
	    font-size: 30px;
	    padding-bottom: 30px;
	}
	.home_version_03 .all-stu-count-area .info-content-box p {
	    color: #ffffff;
	    font-size: 18px;
	    line-height: 27px;
	}
	.home_version_03 .all-stu-count-area .info-content-box .info-btn {
	    margin-top: 50px;
	}
	.home_version_03 .all-stu-count-area .info-content-box .info-btn .information-btn {
	    background: #ec7a11;
	    border: medium none;
	    border-radius: 0;
	    color: #333333;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    font-weight: 600;
	    margin-top: 40px;
	    padding: 15px 40px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.home_version_03 .blog-area {
	    background: #ffffff;
	    padding: 100px 0 70px;
	}
	.home_version_03 .blog-area .section-header-box {
	    margin-bottom: 0;
	}
	.home_version_03 .blog-area .section-header-l h2 {
	    color: #333333;
	    text-transform: none;
	}
	.home_version_03 .blog-area .section-header-l:before {
	    border-bottom: 1px solid #333333;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box {
	    margin: 30px 0;
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .img-box {
	    overflow: hidden;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .img-box img {
	    width: 100%;
	    height: auto;
	    transform: scale(1);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box {
	    padding-bottom: 1px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .blog-content {
	    padding: 25px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .blog-content h3 {
	    margin: 0;
	    line-height: 15px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .blog-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom {
	    position: relative;
	    background: #f9f9f9;
	    min-height: 40px;
	    padding: 0 25px;
	    margin-bottom: -1px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom .content-bottom {
	    display: inline-block;
	    position: relative;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom .blog-time {
	    text-align: center;
	    display: inline-block;
	    position: absolute;
	    bottom: 0;
	    height: 52px;
	    width: 58px;
	    background: #ec7a11;
	    left: 25px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom .blog-time span {
	    color: #ffffff;
	    display: block;
	    font-family: Montserrat;
	    font-size: 14px;
	    font-weight: bold;
	    line-height: 15px;
	    padding-top: 6px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom ul {
	    margin: 0;
	    padding-top: 6px;
	    text-align: right;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom ul li {
	    display: inline-block;
	    text-align: right;
	    font-size: 16px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom ul li:first-child {
	    float: left;
	    margin-left: 78px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom ul li a {
	    color: #b2b2b2;
	    text-decoration: none;
	    font-size: 14px;
	    font-family: 'Montserrat', sans-serif;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box .content-full-box .bolg-content-bottom ul li a .blog-icon {
	    padding-right: 5px;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box:hover .img-box img {
	    transform: scale(1.1);
	}
	.home_version_03 .blog-area .blog-wrapper .blog-single-box:hover .blog-content h3 a {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .blog-area .blog-wrapper .owl-prev,
	.home_version_03 .blog-area .blog-wrapper .owl-next {
	    border: 1px solid #333333;
	    border-radius: 0px;
	    color: #fff;
	    display: inline-block;
	    font-size: 15px;
	    height: 33px;
	    position: absolute;
	    text-align: center;
	    top: -33px;
	    transition: all 0.3s ease-in-out;
	    width: 33px;
	}
	.home_version_03 .blog-area .blog-wrapper .owl-prev:hover,
	.home_version_03 .blog-area .blog-wrapper .owl-next:hover {
	    border: 1px solid #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .blog-area .blog-wrapper .owl-prev:hover .fa-angle-left,
	.home_version_03 .blog-area .blog-wrapper .owl-prev:hover .fa-angle-right,
	.home_version_03 .blog-area .blog-wrapper .owl-next:hover .fa-angle-left,
	.home_version_03 .blog-area .blog-wrapper .owl-next:hover .fa-angle-right {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_03 .partners-area {
	    padding-bottom: 100px;
	}
	.home_version_03 .partners-area .section-header h2 {
	    text-transform: none;
	}
	.home_version_03 .partners-area #partners-carousel-03 {
	    padding: 0 15px;
	    cursor: w-resize;
	}
	.home_version_03 .partners-area .owl-carousel .owl-item {
	    opacity: 0.3;
	}
	.home_version_03 .partners-area .owl-item.active {
	    opacity: 1;
	}
	.home_version_03 footer {
	    background: transparent url("../images/index-03/footer-bg.jpg") no-repeat center center/cover;
	    min-height: 150px;
	    padding-top: 100px;
	}
	/*====================================================================================================
										===[ Index  Version 04  ]===
=====================================================================================================*/
	
	.home_version_04 {
	    /*=======[Index 04 Default Style ]========*/
	    /*=======[Index 04 Navbar Style ]========*/
	    /*=======[Index 04 Header Body Style ]========*/
	    /*=======[Index 04 About And Video Area Style ]========*/
	    /*=======[Index 04 Facilities Area Style ]========*/
	    /*=======[Index 04 Courses Area Style ]========*/
	    /*=======[Index 04 Trausted Student Area Style ]========*/
	    /*=======[Index 04 Register & others Area Style ]========*/
	    /*=======[Index 04 Footer Area Style ]========*/
	}
	.home_version_04 .section-h-medium {
	    padding: 0;
	    font-family: 'Montserrat', sans-serif;
	    text-transform: none;
	    color: #333333;
	    text-decoration: none;
	    font-size: 25px;
	    margin-bottom: 35px;
	}
	.home_version_04 .header-top {
	    background: #ffffff;
	}
	.home_version_04 .header-top .header-top-left ul {
	    margin: 0;
	    padding: 30px 0;
	}
	.home_version_04 .header-top .header-top-left ul li {
	    padding-right: 15px;
	    display: inline-block;
	    color: #333333;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 13px;
	}
	.home_version_04 .header-top .header-top-left ul li .top-icon {
	    font-size: 11px;
	    padding-right: 5px;
	}
	.home_version_04 .header-top .header-top-logo {
	    padding: 23px 0;
	    text-align: center;
	}
	.home_version_04 .header-top .header-top-logo a {
	    text-decoration: none;
	}
	.home_version_04 .header-top .header-top-logo a img {
	    margin-top: -6px;
	}
	.home_version_04 .header-top .header-top-logo a span {
	    color: #323232;
	    font-size: 20px;
	    text-transform: uppercase;
	    font-weight: 700;
	    font-family: 'Montserrat', sans-serif;
	    padding-left: 5px;
	    line-height: 30px;
	    letter-spacing: 1px;
	}
	.home_version_04 .header-top .header-top-right ul {
	    margin: 0;
	    padding: 30px 0;
	    text-align: right;
	}
	.home_version_04 .header-top .header-top-right ul li {
	    display: inline-block;
	    padding-left: 15px;
	}
	.home_version_04 .header-top .header-top-right ul li a {
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    color: #333333;
	    font-size: 13px;
	    text-decoration: none;
	}
	.home_version_04 .header-top .header-top-right ul li a .top-icon {
	    font-size: 11px;
	    padding-right: 5px;
	}
	.home_version_04 .header-body {
	    position: relative;
	    background: #292929;
	    width: 100%;
	}
	.home_version_04 .header-body .edu-navbar {
	    padding: 0px 0;
	    margin-bottom: 0;
	    background: transparent;
	    border-radius: 0px;
	    z-index: 10;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav {
	    padding: 0;
	    margin: 0;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav {
	    margin: 0px auto;
	    width: 62%;
	    float: none;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li {
	    margin: 0 20px;
	    position: relative;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li:last-child a {
	    padding: 14px 0;
	    margin-bottom: 0px;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li:last-child a:before {
	    display: none;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li a {
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	    color: #fff;
	    text-transform: uppercase;
	    font-size: 14px;
	    padding: 14px 0;
	    position: relative;
	    text-decoration: none;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li a:before {
	    background: #ec7a11;
	    height: 2px;
	    width: 100%;
	    bottom: 0px;
	    left: 0;
	    content: "";
	    position: absolute;
	    width: 100%;
	    visibility: hidden;
	    opacity: 0;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li a:hover,
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li a:active,
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li a:focus {
	    background: transparent;
	    outline: none;
	    color: #ec7a11;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li.active a:before,
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li:hover a::before,
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li:focus a::before {
	    visibility: visible;
	    opacity: 1;
	    background: #ec7a11;
	    height: 2px;
	    width: 100%;
	    bottom: 0px;
	    left: 0;
	    content: "";
	    position: absolute;
	    width: 100%;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown {
	    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
	    transition: all 0.3s ease-in-out;
	    padding: 15px 0px;
	    min-width: 225px;
	    background: #ffffff;
	    left: 0px;
	    position: absolute;
	    top: 100%;
	    z-index: 999;
	    transition: all ease-in-out .3s;
	    transform-origin: 0 0 0;
	    position: absolute;
	    text-align: left;
	    visibility: hidden;
	    opacity: 0;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown .dropdown-list-box-02 {
	    position: relative;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown .dropdown-list-box-02:hover .dropdown-list_2 {
	    opacity: 1;
	    visibility: visible;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li {
	    margin-left: 0;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li:last-child {
	    border-bottom: 0;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a {
	    font-size: 14px;
	    text-transform: none;
	    font-weight: 400;
	    display: block;
	    padding: 10px 30px;
	    margin-bottom: 0;
	    color: #333333;
	    transition: all ease-in-out 0.3s;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a:before {
	    display: none;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a .fa-angle-right {
	    color: #000;
	    float: right;
	    font-size: 20px;
	    padding-top: 1px;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 {
	    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
	    transition: all 0.3s ease-in-out;
	    padding: 15px 0px;
	    min-width: 225px;
	    background: #ffffff;
	    left: 225px;
	    position: absolute;
	    top: 40px;
	    z-index: 999;
	    transition: all ease-in-out .3s;
	    transform-origin: 0 0 0;
	    position: absolute;
	    text-align: left;
	    opacity: 0;
	    visibility: hidden;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list-box-03 {
	    position: relative;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list-box-03:hover .dropdown-list_3 {
	    opacity: 1;
	    visibility: visible;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list_3 {
	    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
	    transition: all 0.3s ease-in-out;
	    padding: 15px 0px;
	    min-width: 225px;
	    background: #ffffff;
	    left: 225px;
	    position: absolute;
	    top: 40px;
	    z-index: 999;
	    transition: all ease-in-out .3s;
	    transform-origin: 0 0 0;
	    position: absolute;
	    text-align: left;
	    opacity: 0;
	    visibility: hidden;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li:hover ul.dropdown {
	    opacity: 1;
	    visibility: visible;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li:hover > a {
	    color: #ec7a11;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li.active a {
	    color: #ec7a11;
	}
	.home_version_04 .header-body .edu-navbar .edu-nav .nav li.active a:before {
	    width: 100%;
	    opacity: 1;
	    visibility: visible;
	}
	.home_version_04 .header-body .is-sticky .edu-navbar {
	    padding: 20px 0;
	    background: #ffffff;
	    z-index: 1000;
	    transition: 0.5s ease;
	    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .about_vi_e-area {
	    padding: 100px 0;
	    /*=======[Index 04 Event Style ]========*/
	    /*=======[Index 04 video Style ]========*/
	}
	.home_version_04 .about_vi_e-area .about-content .about-text {
	    margin-bottom: 20px;
	}
	.home_version_04 .about_vi_e-area .about-content .readmore-btn {
	    font-size: 14px;
	    text-transform: uppercase;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    position: relative;
	    padding-right: 15px;
	    color: #666;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .about_vi_e-area .about-content .readmore-btn .readmore-btn-icon {
	    padding-left: 5px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .about_vi_e-area .about-content .readmore-btn:hover {
	    color: #ec7a11;
	}
	.home_version_04 .about_vi_e-area .about-content .readmore-btn:hover .readmore-btn-icon {
	    padding-left: 10px;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding {
	    margin-bottom: 20px;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding:last-child {
	    margin-bottom: 50px;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding .event-img {
	    background: url("../images/index-04/event-time-bg.jpg") no-repeat center center;
	    background-position: center;
	    position: relative;
	    height: 65px;
	    width: 65px;
	    z-index: 1;
	    padding: 0;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding .event-img:before {
	    background: rgba(254, 199, 34, 0.9);
	    content: "";
	    height: 100%;
	    left: 0;
	    position: absolute;
	    width: 100%;
	    top: 0;
	    z-index: -1;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding .event-img ul li {
	    text-align: center;
	    font-size: 15px;
	    text-transform: uppercase;
	    font-weight: 600;
	    font-family: 'Montserrat', sans-serif;
	    line-height: 16px;
	    padding-top: 5px;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding .event-img ul li:first-child {
	    font-size: 25px;
	    font-weight: 700;
	    color: #333333;
	    margin-top: 8px;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding .event-content h3 {
	    margin-top: 8px;
	    padding-bottom: 2px;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding .event-content h3 a {
	    padding-bottom: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    text-transform: none;
	    font-size: 18px;
	    color: #333333;
	    text-decoration: none;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding .event-content .event-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 18px;
	}
	.home_version_04 .about_vi_e-area .event-box .event-box-padding .event-content .event-time span .event-icon {
	    padding-right: 2px;
	}
	.home_version_04 .about_vi_e-area .event-box .readmore-btn-box {
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .about_vi_e-area .event-box .readmore-btn-box .readmore-btn {
	    font-size: 14px;
	    text-transform: uppercase;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    position: relative;
	    padding-right: 15px;
	    color: #666;
	}
	.home_version_04 .about_vi_e-area .event-box .readmore-btn-box .readmore-btn .readmore-btn-icon {
	    padding-left: 5px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .about_vi_e-area .event-box .readmore-btn-box .readmore-btn:hover {
	    color: #ec7a11;
	}
	.home_version_04 .about_vi_e-area .event-box .readmore-btn-box .readmore-btn:hover .readmore-btn-icon {
	    padding-left: 10px;
	}
	.home_version_04 .about_vi_e-area .video-wapper .video-play-btn {
	    background: transparent url("../images/index-04/video-bg.jpg") no-repeat center center/cover;
	    min-height: 290px;
	    text-align: center;
	    z-index: 1;
	}
	.home_version_04 .about_vi_e-area .video-wapper .video-play-btn .video-iframe {
	    background: #ec7a11;
	    border-radius: 50%;
	    color: #ffffff;
	    display: inline-block;
	    font-size: 30px;
	    margin: 31%;
	    line-height: 65px;
	    text-align: center;
	    width: 65px;
	    outline: none;
	}
	.home_version_04 .about_vi_e-area .video-wapper .video-play-btn .video-iframe i {
	    height: 30px;
	    padding-left: 5px;
	    width: 30px;
	}
	.home_version_04 .facilities-area .Facilities-single-box {
	    min-height: 160px;
	    position: relative;
	    padding: 0;
	    overflow: hidden;
	}
	.home_version_04 .facilities-area .Facilities-single-box:before {
	    position: absolute;
	    background: rgba(0, 0, 0, 0.4);
	    height: 100%;
	    width: 100%;
	    top: 0;
	    left: 0;
	    content: "";
	    z-index: 1;
	}
	.home_version_04 .facilities-area .Facilities-single-box .faclilities-img {
	    position: relative;
	    width: 100%;
	    min-height: 160px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .facilities-area .Facilities-single-box h2 {
	    position: absolute;
	    top: 40%;
	    text-align: center;
	    z-index: 1;
	    width: 100%;
	    text-align: center;
	    text-transform: none;
	    font-size: 30px;
	}
	.home_version_04 .facilities-area .Facilities-single-box h2 a {
	    padding-bottom: 0px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    color: #ffffff;
	    text-decoration: none;
	}
	.home_version_04 .facilities-area .Facilities-single-box:hover .faclilities-img {
	    transform: scale(1.1);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .Courses-area-04 {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.home_version_04 .Courses-area-04 .courses-r-margin-bottom {
	    margin-bottom: 30px;
	}
	.home_version_04 .Courses-area-04 .courses-r-margin-bottom:last-child {
	    margin-bottom: 0;
	}
	.home_version_04 .Courses-area-04 .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .Courses-area-04 .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-img {
	    width: auto;
	    overflow: hidden;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-img img {
	    width: 100%;
	    height: 100%;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-img img:hover {
	    transform: scale(1.1);
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price {
	    padding: 0 25px 30px;
	    position: relative;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul {
	    margin: 0;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul li {
	    display: inline-block;
	    text-align: left;
	    color: #ec7a11;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul li:last-child {
	    float: right;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul li span {
	    color: #666666;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul li span img {
	    border: 2px solid #ec7a11;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul .courses-teacher {
	    position: absolute;
	    content: "";
	    top: -10px;
	    left: 15px;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul .price-red {
	    float: right;
	    margin-right: -25px;
	    margin-top: 6px;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul .price-red span {
	    position: relative;
	    z-index: 10;
	    margin-top: 0px;
	    display: block;
	    padding-right: 15px;
	    padding-left: 32px;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 14px;
	    text-transform: uppercase;
	    letter-spacing: .5px;
	    color: #fff;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-price ul .price-red .base {
	    position: relative;
	    z-index: 9;
	    margin-top: -27px;
	    width: auto;
	    height: 34px;
	    line-height: 0;
	    border: 18px solid #ec7a11;
	    border-left: 1.5em solid transparent;
	    left: 0;
	    top: 0;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content {
	    padding: 30px 25px 2px 25px;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content h3 {
	    margin: 0;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-weight: 700;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content h3 a:hover {
	    color: #ec7a11;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content p {
	    margin-bottom: 25px;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content .read_more-btn {
	    color: #666;
	    font-size: 14px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    text-decoration: none;
	    text-transform: uppercase;
	    line-height: 30px;
	    text-align: center;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content .read_more-btn i {
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content .read_more-btn:hover {
	    color: #ec7a11;
	}
	.home_version_04 .Courses-area-04 .single-courses .courses-content .read_more-btn:hover i {
	    padding-left: 10px;
	}
	.home_version_04 .trausted-stu-area {
	    min-height: 100px;
	}
	.home_version_04 .trausted-stu-area .trausted-content {
	    position: relative;
	    background: transparent url("../images/index-04/trausted-stu.jpg") no-repeat center center/cover;
	    padding: 58px 68px;
	}
	.home_version_04 .trausted-stu-area .trausted-content:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    width: 100%;
	    left: 0;
	}
	.home_version_04 .trausted-stu-area .trausted-content .section-h-medium {
	    padding-bottom: 0px;
	    margin-bottom: 15px;
	    text-align: left;
	    color: #ffffff;
	}
	.home_version_04 .trausted-stu-area .trausted-content p {
	    color: #666666;
	    font-size: 18px;
	    font-family: 'Montserrat', sans-serif;
	    margin: 0;
	    padding: 0px;
	    text-align: left;
	    color: #ffffff;
	}
	.home_version_04 .trausted-stu-area .trausted-content .trausted-stu-btn {
	    margin: 5px 0;
	    width: 208px;
	    height: 55px;
	    background: #ffffff;
	    text-align: center;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .trausted-stu-area .trausted-content .trausted-stu-btn:hover {
	    background: #ec7a11;
	}
	.home_version_04 .trausted-stu-area .trausted-content .trausted-stu-btn a {
	    line-height: 55px;
	    color: #323232;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	.home_version_04 .reg-news_others {
	    background: #ffffff;
	    padding: 100px 0;
	    /*===[Index 04 News Style ]====*/
	    /*===[Index 04 Register Style ]====*/
	    /*===[Index 04 Success Story Style ]====*/
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .img-box {
	    min-height: 200px;
	    width: auto;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .img-box img {
	    width: 100%;
	    height: 100%;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .news-content {
	    margin-top: 20px;
	    border-bottom: 1px solid #b2b2b2;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .news-content:last-child {
	    margin-bottom: 20px;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .news-content h3 {
	    margin: 0;
	    padding-bottom: 10px;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .news-content h3 a {
	    margin: 0;
	    color: #333333;
	    font-size: 18px;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .news-content .news-time {
	    margin-bottom: 20px;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .news-content .news-time span {
	    color: #b2b2b2;
	    font-size: 14px;
	    margin-right: 25px;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .news-content .news-time span .event-icon {
	    padding-right: 2px;
	}
	.home_version_04 .reg-news_others .news-item-box .single-item .news-content p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #b2b2b2;
	}
	.home_version_04 .reg-news_others .news-item-box a {
	    color: #666;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .reg-news_others .news-item-box a .readmore-btn-icon {
	    padding-left: 5px;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .reg-news_others .news-item-box a:hover {
	    color: #ec7a11;
	}
	.home_version_04 .reg-news_others .news-item-box a:hover .readmore-btn-icon {
	    padding-left: 10px;
	}
	.home_version_04 .reg-news_others .regi-full-box {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .reg-news_others .regi-full-box .regi-content {
	    text-align: center;
	    background: url("../images/index-04/regirster-bg.jpg") no-repeat center center/cover;
	    position: relative;
	    z-index: 0;
	    padding: 35px 30px 0;
	    min-height: 170px;
	    margin-bottom: 15px;
	}
	.home_version_04 .reg-news_others .regi-full-box .regi-content:before {
	    background: rgba(254, 199, 34, 0.85);
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    width: 100%;
	    left: 0;
	    z-index: -1;
	    border-bottom: 35px solid #ffffff;
	    border-right: 360px solid transparent;
	}
	.home_version_04 .reg-news_others .regi-full-box .regi-content:after {
	    position: absolute;
	    content: "";
	    left: 0;
	    bottom: 0;
	    height: 35px;
	    width: 180px;
	    background: url("../images/index-04/regi-shap.png") no-repeat center center/cover;
	}
	.home_version_04 .reg-news_others .regi-full-box .regi-content p {
	    font-family: 'Montserrat', sans-serif;
	    color: #333333;
	    font-size: 19px;
	    z-index: 2;
	    line-height: 30px;
	}
	.home_version_04 .reg-news_others .regi-full-box .register-form {
	    padding: 0 30px 35px;
	}
	.home_version_04 .reg-news_others .regi-full-box .register-form .form-group input {
	    background: transparent;
	    border-color: #bababa;
	    border-radius: 0px;
	    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0);
	    height: 40px;
	    padding: 10px;
	    transition: all 0.3s ease-in-out;
	    width: 100%;
	}
	.home_version_04 .reg-news_others .regi-full-box .register-form .form-group input:focus {
	    border-color: #ec7a11;
	    outline: none;
	    box-shadow: none;
	}
	.home_version_04 .reg-news_others .regi-full-box .register-form .register-btn-box {
	    margin-top: 5px;
	}
	.home_version_04 .reg-news_others .regi-full-box .register-form .register-btn-box .register-btn {
	    height: 40px;
	    background: #232323;
	    width: 100%;
	    color: #fff;
	    font-size: 15px;
	    border: none;
	    border-radius: 0px;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    transition: all 0.3s ease-in-out;
	}
	.home_version_04 .reg-news_others .regi-full-box .register-form .register-btn-box .register-btn:hover {
	    background: #ec7a11;
	}
	.home_version_04 .reg-news_others .success-story .success-story-box .single-item {
	    text-align: center;
	}
	.home_version_04 .reg-news_others .success-story .success-story-box .single-item .img-box {
	    width: 100%;
	}
	.home_version_04 .reg-news_others .success-story .success-story-box .single-item .img-box img {
	    min-height: 300px;
	    width: 100%;
	}
	.home_version_04 .reg-news_others .success-story .success-author-info {
	    margin-top: 35px;
	}
	.home_version_04 .reg-news_others .success-story .success-author-info .section-h-medium {
	    margin-bottom: 6px;
	}
	.home_version_04 .reg-news_others .success-story .success-author-info .section-h-medium a {
	    font-family: 'Montserrat', sans-serif;
	    text-transform: uppercase;
	    color: #333333;
	    text-decoration: none;
	    font-size: 16px;
	}
	.home_version_04 .reg-news_others .success-story .success-author-info span {
	    font-size: 15px;
	}
	.home_version_04 .reg-news_others .success-story .owl-controls {
	    margin-top: 25px;
	}
	.home_version_04 .reg-news_others .success-story .owl-controls .owl-page span {
	    display: block;
	    background: #d4d4d4;
	    opacity: 1;
	}
	.home_version_04 .reg-news_others .success-story .owl-controls .owl-page.active span {
	    background: #ec7a11;
	}
	.home_version_04 footer {
	    background: transparent url("../images/index-04/footer-bg.jpg") no-repeat center center/cover;
	    min-height: 150px;
	    padding-top: 100px;
	}
	/*====================================================================================================
										===[ Single Courses ]===
=====================================================================================================*/
	
	.single-courses_v {
	    /*===[Single Courses Header Style ]====*/
	    /*===[ Single Courses_01 Single Courses Area Style ]====*/
	    /*===[ Single Courses Style tow]====*/
	}
	.single-courses_v header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.single-courses_v header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.single-courses_v header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.single-courses_v header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.single-courses_v header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.single-courses_v header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.single-courses_v header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area {
	    padding: 100px 0;
	    min-height: 1000px;
	    background: #ffffff;
	}
	.single-courses_v .single-courses-area .sidebar-right .sidebar-content {
	    width: 85%;
	    float: right;
	}
	.single-courses_v .single-courses-area .single-curses-contert h2 {
	    font-size: 30px;
	    text-align: left;
	}
	.single-courses_v .single-courses-area .single-curses-contert h3 {
	    font-weight: 700;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option {
	    padding: 40px 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .border-left {
	    border-right: 1px solid #e5e5e5;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .border-left:last-child {
	    border-right: none;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .teacher-info img {
	    float: left;
	    margin-right: 10px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .teacher-info .teacher-name span {
	    font-size: 13px;
	    display: inline-block;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .teacher-info .teacher-name span:last-child {
	    margin-top: 5px;
	    font-size: 15px;
	    font-weight: 600;
	    text-transform: uppercase;
	    color: #333333;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .review-rank {
	    padding-left: 20px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .review-rank span {
	    font-size: 13px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .review-rank .rank-icons {
	    padding-top: 5px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .review-rank .rank-icons ul {
	    margin: 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .review-rank .rank-icons ul li {
	    display: inline-block;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .review-rank .rank-icons ul li .review-icon {
	    color: #ec7a11;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .categories {
	    padding-left: 20px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .categories span {
	    font-size: 13px;
	    display: table;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .categories span:last-child {
	    color: #333333;
	    margin-top: 5px;
	    font-weight: 600;
	    font-size: 15px;
	    text-transform: uppercase;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .students_105 {
	    padding-left: 20px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .students_105 span {
	    display: block;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-option .students_105 span:last-child {
	    color: #333333;
	    margin-top: 5px;
	    font-weight: 600;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	}
	.single-courses_v .single-courses-area .single-curses-contert .details-img-bxo img {
	    height: 100%;
	    width: 100%;
	}
	.single-courses_v .single-courses-area .single-curses-contert .description-content h2 {
	    padding-top: 40px;
	    padding-bottom: 15px;
	    border-bottom: 3px solid #eaeaea;
	}
	.single-courses_v .single-courses-area .single-curses-contert .description-content p {
	    margin: 40px 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .description-content .requirements {
	    padding-bottom: 20px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .description-content .requirements ul li {
	    font-family: 'Montserrat', sans-serif;
	    line-height: 24px;
	    font-size: 14px;
	    padding-left: 15px;
	    position: relative;
	    color: #666666;
	}
	.single-courses_v .single-courses-area .single-curses-contert .description-content .requirements ul li:before {
	    color: #ec7a11;
	    position: absolute;
	    content: "\f101";
	    font-family: FontAwesome;
	    left: 0;
	    font-size: 15px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .description-content .count-list ol {
	    margin: 0;
	    padding: 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .description-content .count-list ol li {
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	    line-height: 24px;
	    font-size: 14px;
	    padding-left: 20px;
	    counter-increment: count-me 1;
	}
	.single-courses_v .single-courses-area .single-curses-contert .description-content .count-list ol li:before {
	    color: #ec7a11;
	    content: counter(count-me, decimal) ". ";
	    display: block;
	    left: -20px;
	    max-height: 0;
	    max-width: 0;
	    position: relative;
	    top: 0.05em;
	    font-weight: 700;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box h2 {
	    border-bottom: 3px solid #eaeaea;
	    margin-bottom: 40px;
	    padding: 40px 0 15px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel + .panel {
	    margin-top: 30px;
	    box-shadow: none;
	    margin-bottom: 35px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel-default {
	    border-color: transparent;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-heading {
	    background-color: #fafafa;
	    border: 0px solid;
	    border-color: transparent;
	    padding: 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-title {
	    width: 100%;
	    height: 57px;
	    display: table;
	    padding: 0 30px;
	    -webkit-transition: all .3s linear;
	    transition: all .3s linear;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-title .click {
	    background: #fafafa;
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-title span {
	    float: right;
	    font-size: 15px;
	    padding-right: 30px;
	    font-family: 'Montserrat', sans-serif;
	    color: #333333;
	    line-height: 20px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-title a {
	    display: block;
	    color: #333333;
	    display: table-cell;
	    font-size: 18px;
	    font-weight: 500;
	    position: relative;
	    transition: all 0.4s linear 0s;
	    vertical-align: middle;
	    text-decoration: none;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-title a.collapsed:before {
	    position: absolute;
	    content: "\f077";
	    font-family: FontAwesome;
	    font-size: 15px;
	    text-align: center;
	    top: 50%;
	    right: 0;
	    -webkit-transform: translateY(-50%) rotate(0deg);
	    transform: translateY(-50%) rotate(0deg);
	    -webkit-transition: all .4s ease-in;
	    transition: all .3s ease-in;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-title a:before {
	    position: absolute;
	    content: "\f078";
	    font-family: FontAwesome;
	    font-size: 15px;
	    text-align: center;
	    top: 50%;
	    right: 0;
	    -webkit-transition: all .4s ease-in;
	    transition: all .4s ease-in;
	    line-height: 38px;
	    -webkit-transform: translateY(-50%) rotate(180deg);
	    transform: translateY(-50%) rotate(180deg);
	    color: #ec7a11;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-body {
	    background: #fafafa;
	    padding: 30px 45px;
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-body .curriculum-single {
	    margin-bottom: 25px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-body .curriculum-single:last-child {
	    margin-bottom: 0px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-body .curriculum-single .lecture span {
	    padding-right: 50px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-body .curriculum-single .lecture i {
	    color: #ec7a11;
	    font-size: 18px;
	    padding-right: 10px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-body .curriculum-single .lecture-btn {
	    background: #ec7a11;
	    height: 24px;
	    text-align: center;
	    width: 68px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .curriculum-text-box .curriculum-section .panel-group .panel .panel-body .curriculum-single .lecture-btn a {
	    color: #333333;
	    line-height: 2;
	    font-size: 11px;
	    text-decoration: none;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content h2 {
	    border-bottom: 3px solid #eaeaea;
	    margin-bottom: 40px;
	    padding: 40px 0 15px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .five-star-rating {
	    background: #fafafa;
	    height: 180px;
	    text-align: center;
	    width: 195px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .five-star-rating .rating-box {
	    padding: 31px 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .five-star-rating .rating-box .five {
	    font-size: 70px;
	    color: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	    line-height: 50px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .five-star-rating .rating-box ul {
	    margin: 0;
	    padding: 10px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .five-star-rating .rating-box ul li {
	    color: #ec7a11;
	    display: inline-block;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .five-star-rating .rating-box ul li i {
	    font-size: 20px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .rating-btom-padding {
	    margin-bottom: 15px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .rating-btom-padding:last-child {
	    margin-bottom: 0px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .rating-btom-padding .right-rating-text span {
	    font-weight: 600;
	    padding-right: 30px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .rating-btom-padding .right-rating-text .rating-color-yellow {
	    background: #ec7a11;
	    padding: 3px 140px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .review-content .rating-btom-padding .right-rating-text .right-rating-color {
	    background: #fafafa;
	    padding: 3px 140px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments {
	    margin-top: 40px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item {
	    margin-bottom: 50px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item:last-child {
	    margin-bottom: 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .img-box {
	    height: 65px;
	    padding: 0;
	    width: 65px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .img-box img {
	    height: 100%;
	    width: 100%;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar {
	    padding: 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar .comment-text {
	    padding-left: 15px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar .comment-text ul {
	    margin: 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar .comment-text ul .name {
	    display: inline-block;
	    font-size: 14px;
	    font-weight: 600;
	    padding-bottom: 10px;
	    text-transform: uppercase;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar .comment-text ul .name .comment-author {
	    padding-right: 5px;
	    padding-top: 10px;
	    text-transform: none;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar .comment-text ul .name .comment-author li {
	    display: inline-block;
	    color: #ec7a11;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar .comment-text ul .name .comment-author li:first-child {
	    color: #666666;
	    font-weight: 500;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar .comment-text ul .comment-date {
	    float: right;
	}
	.single-courses_v .single-courses-area .single-curses-contert .comments .comment-single-item .comment-left-bar .comment-text p {
	    margin: 0;
	    padding-top: 10px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment {
	    margin-top: 40px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group {
	    margin-bottom: 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group label {
	    text-transform: uppercase;
	    font-weight: 600;
	    position: relative;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group label:before {
	    color: #ec7a11;
	    content: "*";
	    position: absolute;
	    right: -11px;
	    top: 0;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group input {
	    border-color: currentcolor currentcolor #b2b2b2;
	    border-style: none none solid;
	    border-width: 0 0 1px;
	    box-shadow: none;
	    height: 50px;
	    transition: all 0.3s ease 0s;
	    width: 100%;
	    outline: none;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group textarea {
	    border-color: currentcolor currentcolor #b2b2b2;
	    border-style: none none solid;
	    border-width: 0 0 1px;
	    box-shadow: none;
	    height: 70px;
	    margin-top: 20px;
	    resize: none;
	    transition: all 0.3s ease 0s;
	    width: 100%;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group input,
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group textarea {
	    margin-bottom: 30px;
	    font-size: 13px;
	    padding-left: 10px;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group input:focus,
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group textarea:focus {
	    border-color: #ec7a11;
	    outline: none;
	}
	.single-courses_v .single-courses-area .single-curses-contert .leave_a_comment .form-group button {
	    background: #ec7a11;
	    border: medium none;
	    border-radius: 0px;
	    float: left;
	    color: #333333;
	    font-weight: 600;
	    display: block;
	    font-size: 15px;
	    margin: 30px auto 0;
	    padding: 13px 40px;
	    text-transform: uppercase;
	    transition: all 0.5s ease 0s;
	    margin-top: 20px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price {
	    text-align: center;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price .price-hading {
	    height: 50px;
	    background: #333333;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price .price-hading h3 {
	    color: #ffffff;
	    line-height: 50px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price .price-text {
	    background: #fafafa;
	    padding: 30px 30px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price .price-text span {
	    font-size: 60px;
	    font-weight: 700;
	    line-height: 60px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price .price-text .dolar-sign {
	    font-size: 40px;
	    font-weight: 700;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price .price-text p {
	    font-size: 20px;
	    margin: 0;
	    padding: 15px 0 20px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price .price-text .price-btn-box {
	    background: #ec7a11;
	    border: medium none;
	    border-radius: 0px;
	    height: 47px;
	    width: 175px;
	    margin: 0px auto;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-price .price-text .price-btn-box .price-btn {
	    color: #333333;
	    font-size: 13px;
	    font-weight: 600;
	    width: 100%;
	    line-height: 47px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features {
	    margin-top: 40px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-hading {
	    text-align: center;
	    height: 50px;
	    background: #333333;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-hading h3 {
	    color: #ffffff;
	    line-height: 50px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-text {
	    background: #fafafa;
	    padding: 30px 30px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-text ul {
	    margin: 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-text ul li {
	    margin-bottom: 30px;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-text ul li:last-child {
	    margin-bottom: 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-text ul li span {
	    text-transform: uppercase;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-text ul li span:last-child {
	    float: right;
	    width: 35%;
	    font-weight: 600;
	}
	.single-courses_v .single-courses-area .sidebar-content .courses-features .features-text ul li span i {
	    margin-right: 10px;
	    color: #ec7a11;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link {
	    margin-top: 40px;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-hading {
	    text-align: center;
	    height: 50px;
	    background: #333333;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-hading h3 {
	    color: #ffffff;
	    line-height: 50px;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon {
	    background: #fafafa;
	    padding: 40px 25px;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul {
	    margin: 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li {
	    display: inline-block;
	    float: left;
	    margin: 8px;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li a {
	    background: red none repeat scroll 0 0;
	    color: #ffffff;
	    display: block;
	    height: 48px;
	    width: 48px;
	    font-size: 23px;
	    text-align: center;
	    padding: 8px;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li a i {
	    height: 25px;
	    width: 25px;
	    text-align: center;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li .facebook {
	    background: #3b5998;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li .google {
	    background: #dd4b39;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li .instagram {
	    background: #f56040;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li .twitter {
	    background: #1da1f2;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li .skype {
	    background: #00aff0;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li .youtube {
	    background: #cd201f;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li .linkedin {
	    background: #8d6cab;
	}
	.single-courses_v .single-courses-area .sidebar-content .share-socila-link .social-icon ul li .pinterest {
	    background: #bd081c;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses {
	    margin-top: 40px;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-hading {
	    text-align: center;
	    height: 50px;
	    background: #333333;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-hading h3 {
	    color: #ffffff;
	    line-height: 50px;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-text {
	    background: #fafafa;
	    padding: 30px 30px;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-text ul {
	    margin: 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-text ul li {
	    margin-bottom: 30px;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-text ul li:last-child {
	    margin-bottom: 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-text ul li span {
	    text-transform: uppercase;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-text ul li span:last-child {
	    float: right;
	    width: 25%;
	    font-weight: 600;
	}
	.single-courses_v .single-courses-area .sidebar-content .all-corses .all-corses-text ul li span i {
	    margin-right: 10px;
	    color: #ec7a11;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses {
	    margin-top: 40px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-hading {
	    text-align: center;
	    height: 50px;
	    background: #333333;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-hading h3 {
	    font-size: 18px;
	    color: #ffffff;
	    line-height: 50px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text {
	    background: #fafafa;
	    padding: 40px 45px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text .latest-single {
	    margin-bottom: 30px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text .latest-single:last-child {
	    margin-bottom: 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text .latest-single .course-image {
	    padding: 0;
	    height: 80px;
	    width: 100px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text .latest-single .course-image img {
	    width: 100%;
	    height: auto;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text .latest-single .course-prefix {
	    padding: 0 0 0 15px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text .latest-single .course-prefix h3 {
	    margin-top: -8px;
	    margin-bottom: 10px;
	    padding: 0;
	    font-size: 16px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text .latest-single .course-prefix h3 a {
	    color: #333333;
	    text-decoration: none;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-courses .latest-courses-text .latest-single .course-prefix .latest-btn a {
	    background: #ec7a11;
	    color: #333333;
	    font-size: 13px;
	    line-height: 24px;
	    padding: 4px 15px;
	    text-decoration: none;
	}
	.single-courses_v .single-courses-area .sidebar-content .special-offer {
	    background: url("../images/Single_cources/discount-offer.jpg") no-repeat center center/cover;
	    position: relative;
	    margin-top: 40px;
	    padding: 55px 30px;
	    z-index: 1;
	}
	.single-courses_v .single-courses-area .sidebar-content .special-offer:before {
	    background: #3f51b5;
	    opacity: 0.8;
	    content: "";
	    height: 100%;
	    position: absolute;
	    top: 0;
	    width: 100%;
	    left: 0;
	    z-index: -1;
	}
	.single-courses_v .single-courses-area .sidebar-content .special-offer .special-text p {
	    text-transform: uppercase;
	    margin: 0;
	    color: #ffffff;
	}
	.single-courses_v .single-courses-area .sidebar-content .special-offer .special-text h2 {
	    color: #ffffff;
	    line-height: 50px;
	    padding: 10px 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .special-offer .special-text .offer-btn-box {
	    background: #ec7a11;
	    border: medium none;
	    border-radius: 0px;
	    height: 48px;
	    width: 180px;
	    margin: 10px auto 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .special-offer .special-text .offer-btn-box .offer-btn {
	    color: #333333;
	    font-size: 15px;
	    font-weight: 600;
	    width: 100%;
	    line-height: 47px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post {
	    margin-top: 40px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-hading {
	    text-align: center;
	    height: 50px;
	    background: #333333;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-hading h3 {
	    font-size: 18px;
	    color: #ffffff;
	    line-height: 50px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text {
	    background: #fafafa;
	    padding: 40px 30px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single {
	    margin-bottom: 30px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single:last-child {
	    margin-bottom: 0;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single .course-image {
	    padding: 0;
	    height: 80px;
	    width: 100px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single .course-image img {
	    width: 100%;
	    height: auto;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single .course-prefix {
	    padding: 0 0 0 15px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single .course-prefix h3 {
	    margin-top: -8px;
	    margin-bottom: 10px;
	    padding: 0;
	    font-size: 16px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single .course-prefix h3 a {
	    color: #333333;
	    text-decoration: none;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single .course-prefix p {
	    font-size: 14px;
	}
	.single-courses_v .single-courses-area .sidebar-content .latest-post .latest-post-text .latest-post-single .course-prefix p i {
	    padding-right: 10px;
	}
	.single-courses_v .single-courses-area .sidebar-content {
	    width: 85%;
	}
	.single-courses_v .single-courses-area-01 {
	    background: #ffffff;
	}
	.single-courses_v .single-courses-area-01 .section-header-box {
	    margin-bottom: 0;
	    margin: 0 15px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	    transition: all 0.3s ease-in-out;
	    margin: 40px 0;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses figure {
	    position: relative;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses figure .figure-img {
	    position: relative;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses figure .figure-img img {
	    width: 100%;
	    height: auto;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses figure .figure-img::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100%;
	    width: 100%;
	    background: rgba(254, 199, 34, 0.1);
	    z-index: 1;
	    content: "";
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses figure figcaption {
	    position: absolute;
	    text-align: center;
	    top: 0;
	    transform: translateY(-50%);
	    width: 100%;
	    z-index: 100;
	    visibility: hidden;
	    transition: all 0.3s ease-in-out;
	    opacity: 0;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses figure figcaption div a {
	    background: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    border-radius: 0;
	    color: #333333;
	    display: inline-block;
	    font-size: 15px;
	    padding: 10px 30px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses figure:before {
	    background: rgba(0, 0, 0, 0.4);
	    content: "";
	    height: 0%;
	    opacity: 0;
	    position: absolute;
	    width: 100%;
	    z-index: 1;
	    visibility: hidden;
	    top: 0;
	    left: 0;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses:hover figcaption {
	    visibility: visible;
	    top: 50%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.15);
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box {
	    padding: 25px 20px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .courses-content h3 {
	    margin: 0;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .courses-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-weight: 600;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .courses-content ul {
	    margin: 0;
	    padding-bottom: 15px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .courses-content ul li {
	    display: table-cell;
	    font-size: 15px;
	    color: #666666;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .courses-content ul li:first-child {
	    padding-right: 20px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .rank-icons ul {
	    display: inline-block;
	    padding: 5px 0;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .rank-icons ul li {
	    display: inline-block;
	    color: #ec7a11;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .rank-icons span {
	    padding-left: 15px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul {
	    border-top: 1px solid #b2b2b2;
	    padding-top: 15px;
	    margin-bottom: 0;
	    text-align: right;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul li {
	    display: inline-block;
	    text-align: right;
	    color: #b2b2b2;
	    font-size: 16px;
	    padding-left: 10px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul li:first-child {
	    color: #b2b2b2;
	    float: left;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    font-weight: 600;
	    padding-left: 0px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses:hover .courses-content-box .courses-content h3 a {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .single-courses:hover figure::before {
	    visibility: visible;
	    height: 100%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-prev,
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-next {
	    border: 1px solid #333333;
	    border-radius: 0px;
	    color: #fff;
	    display: inline-block;
	    font-size: 15px;
	    height: 33px;
	    position: absolute;
	    text-align: center;
	    top: -33px;
	    transition: all 0.3s ease-in-out;
	    width: 33px;
	    padding: 0;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-prev:hover,
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-next:hover {
	    border: 1px solid #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-prev:hover .fa-angle-left,
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-prev:hover .fa-angle-right,
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-next:hover .fa-angle-left,
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-next:hover .fa-angle-right {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-prev {
	    right: 50px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-prev .fa-angle-left {
	    color: #333333;
	    font-size: 18px;
	    line-height: 30px;
	    transition: all 0.3s ease-in-out;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-next {
	    right: 10px;
	}
	.single-courses_v .single-courses-area-01 .courses-wrapper .owl-next .fa-angle-right {
	    color: #333333;
	    font-size: 18px;
	    line-height: 30px;
	    transition: all 0.3s ease-in-out;
	}
	/*====================================================================================================
										===[ Courses Style ]===
=====================================================================================================*/
	
	.courses {
	    /*=======[Courses Style 01 ]========*/
	    /*=======[Courses Style 02 ]========*/
	    /*=======[Courses Style 03 ]========*/
	    /*=======[Courses Style 04 ]========*/
	}
	.courses header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.courses header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.courses header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.courses header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.courses header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.courses header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.courses header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.courses header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-1 {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.courses .courses-1 .courses-r-margin-bottom {
	    margin-bottom: 30px;
	}
	.courses .courses-1 .courses-r-margin-bottom:last-child {
	    margin-bottom: 0;
	}
	.courses .courses-1 .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-1 .single-courses figure {
	    position: relative;
	}
	.courses .courses-1 .single-courses figure:before {
	    background: rgba(0, 0, 0, 0.4);
	    content: "";
	    height: 0%;
	    opacity: 0;
	    position: absolute;
	    width: 100%;
	    z-index: 1;
	    visibility: hidden;
	    top: 0;
	    left: 0;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-1 .single-courses figure .figure-img {
	    position: relative;
	}
	.courses .courses-1 .single-courses figure .figure-img img {
	    width: 100%;
	    height: auto;
	}
	.courses .courses-1 .single-courses figure .figure-img::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100%;
	    width: 100%;
	    background: rgba(254, 199, 34, 0.1);
	    z-index: 1;
	    content: "";
	}
	.courses .courses-1 .single-courses figure figcaption {
	    position: absolute;
	    text-align: center;
	    top: 0;
	    left: 0;
	    transform: translateY(-50%);
	    width: 100%;
	    z-index: 100;
	    visibility: hidden;
	    transition: all 0.3s ease-in-out;
	    opacity: 0;
	}
	.courses .courses-1 .single-courses figure figcaption div a {
	    background: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    border-radius: 0;
	    color: #333333;
	    display: inline-block;
	    font-size: 15px;
	    padding: 10px 30px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.courses .courses-1 .single-courses:hover figcaption {
	    visibility: visible;
	    top: 50%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-1 .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-1 .single-courses .courses-content-box {
	    padding: 25px;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content h3 {
	    margin: 0;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content ul {
	    margin: 0;
	    padding-bottom: 15px;
	    float: left;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content ul li {
	    display: table-cell;
	    font-size: 15px;
	    color: #ec7a11;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content-bottom{
		margin-top: 41px;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content ul li span {
	    font-weight: 600;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content span{
		float: right;
		font-weight: 600;
		color: #666;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content-bottom ul {
	    border-top: 1px solid #ebebeb;
	    padding-top: 15px;
	    margin-bottom: 0;
	    text-align: right;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content-bottom ul li {
	    display: inline-block;
	    text-align: right;
	    color: #b2b2b2;
	    font-size: 16px;
	    padding-left: 10px;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content-bottom ul li:first-child {
	    color: #b2b2b2;
	    float: left;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    font-weight: 600;
	    padding-left: 0px;
	}
	.courses .courses-1 .single-courses .courses-content-box .courses-content-bottom ul li:nth-child(2) {
	    float: left;
	}
	.courses .courses-1 .single-courses:hover .courses-content-box .courses-content h3 a {
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-1 .single-courses:hover figure::before {
	    visibility: visible;
	    height: 100%;
	    left: 0;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-02 {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.courses .courses-02 .courses-r-margin-bottom {
	    margin-bottom: 30px;
	}
	.courses .courses-02 .courses-r-margin-bottom:last-child {
	    margin-bottom: 0;
	}
	.courses .courses-02 .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-02 .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-02 .single-courses figure {
	    position: relative;
	}
	.courses .courses-02 .single-courses figure .figure-img {
	    position: relative;
	    width: auto;
	    overflow: hidden;
	}
	.courses .courses-02 .single-courses figure .figure-img img {
	    width: 100%;
	    height: 100%;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-02 .single-courses figure .figure-img img:hover {
	    transform: scale(1.1);
	}
	.courses .courses-02 .single-courses figure .figure-img .sale-banner {
	    position: absolute;
	    content: "";
	    top: -15px;
	    left: -55px;
	    padding: 30px 50px 10px;
	    z-index: 100;
	    background: #ec7a11;
	    transform: rotate(-45deg);
	    text-transform: uppercase;
	    color: #ffffff;
	    font-weight: 700;
	    font-family: 'Montserrat', sans-serif;
	}
	.courses .courses-02 .single-courses figure figcaption ul {
	    margin: 0;
	}
	.courses .courses-02 .single-courses figure figcaption ul li {
	    display: inline-block;
	    text-align: left;
	    font-family: 'Montserrat', sans-serif;
	    color: #ec7a11;
	}
	.courses .courses-02 .single-courses figure figcaption ul li:last-child {
	    float: right;
	}
	.courses .courses-02 .single-courses figure figcaption ul .courses-free {
	    position: absolute;
	    bottom: 0;
	    z-index: 100;
	    left: 0;
	    background: #ffffff;
	    padding: 7px 15px;
	    text-transform: uppercase;
	    line-height: 30px;
	    font-size: 18px;
	}
	.courses .courses-02 .single-courses figure figcaption ul .courses-free span {
	    font-size: 14px;
	    text-decoration: line-through;
	    font-style: italic;
	    color: #666666;
	}
	.courses .courses-02 .single-courses figure figcaption ul .rating {
	    position: absolute;
	    bottom: 0;
	    z-index: 100;
	    right: 0;
	    padding: 0px 15px 13px;
	    color: #ec7a11;
	    font-size: 12px;
	}
	.courses .courses-02 .single-courses .courses-content-box {
	    padding: 20px;
	}
	.courses .courses-02 .single-courses .courses-content-box .courses-content h3 {
	    margin: 0;
	}
	.courses .courses-02 .single-courses .courses-content-box .courses-content h3 a {
		color: #323232;
		margin: 0px;
		text-decoration: none;
		font-weight: 700;
		font-size: 15px;
		transition: all 0.3s ease-in-out;
	}
	.courses .courses-02 .single-courses .courses-content-box .courses-content h3 a:hover {
	    color: #ec7a11;
	}
	.courses .courses-03 {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.courses .courses-03 .courses-r-margin-bottom {
	    margin-bottom: 30px;
	}
	.courses .courses-03 .courses-r-margin-bottom:last-child {
	    margin-bottom: 0;
	}
	.courses .courses-03 .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-03 .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-03 .single-courses .courses-img {
	    width: auto;
	    overflow: hidden;
	}
	.courses .courses-03 .single-courses .courses-img img {
	    width: 100%;
	    height: 100%;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-03 .single-courses .courses-img img:hover {
	    transform: scale(1.1);
	}
	.courses .courses-03 .single-courses .courses-price {
	    padding: 0 25px 30px;
	    position: relative;
	}
	.courses .courses-03 .single-courses .courses-price ul {
	    margin: 0;
	}
	.courses .courses-03 .single-courses .courses-price ul li {
	    display: inline-block;
	    text-align: left;
	    color: #ec7a11;
	}
	.courses .courses-03 .single-courses .courses-price ul li:last-child {
	    float: right;
	}
	.courses .courses-03 .single-courses .courses-price ul li span {
	    color: #666666;
	}
	.courses .courses-03 .single-courses .courses-price ul li span img {
	    border: 2px solid #ec7a11;
	}
	.courses .courses-03 .single-courses .courses-price ul .courses-teacher {
	    position: absolute;
	    content: "";
	    top: -5px;
	    left: 15px;
	}
	.courses .courses-03 .single-courses .courses-price ul .price-red {
	    float: right;
	    margin-right: -25px;
	    margin-top: 6px;
	}
	.courses .courses-03 .single-courses .courses-price ul .price-red span {
	    position: relative;
	    z-index: 10;
	    margin-top: 0px;
	    display: block;
	    padding-right: 15px;
	    padding-left: 32px;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 14px;
	    text-transform: uppercase;
	    letter-spacing: .5px;
	    color: #fff;
	}
	.courses .courses-03 .single-courses .courses-price ul .price-red .base {
	    position: relative;
	    z-index: 9;
	    margin-top: -27px;
	    width: auto;
	    height: 34px;
	    line-height: 0;
	    border: 18px solid #ec7a11;
	    border-left: 1.5em solid transparent;
	    left: 0;
	    top: 0;
	}
	.courses .courses-03 .single-courses .courses-content {
	    padding: 30px 25px 2px 25px;
	}
	.courses .courses-03 .single-courses .courses-content h3 {
	    margin: 0;
	}
	.courses .courses-03 .single-courses .courses-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-weight: 700;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-03 .single-courses .courses-content h3 a:hover {
	    color: #ec7a11;
	}
	.courses .courses-03 .single-courses .courses-content p {
	    margin-bottom: 25px;
	}
	.courses .courses-03 .single-courses .courses-content .read_more-btn {
	    color: #333333;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    text-decoration: none;
	    text-transform: uppercase;
	    line-height: 30px;
	    text-align: center;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-03 .single-courses .courses-content .read_more-btn i {
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-03 .single-courses .courses-content .read_more-btn:hover {
	    color: #ec7a11;
	}
	.courses .courses-03 .single-courses .courses-content .read_more-btn:hover i {
	    padding-left: 10px;
	}
	.courses .courses-area-04 {
	    background: #f9f9f9;
	    padding: 100px 0 80px;
	}
	.courses .courses-area-04 .section-header-box {
	    margin-bottom: 0;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	    transition: all 0.3s ease-in-out;
	    margin: 20px 0;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses figure {
	    position: relative;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses figure .figure-img {
	    position: relative;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses figure .figure-img img {
	    width: 100%;
	    height: auto;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses figure .figure-img::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100%;
	    width: 100%;
	    background: rgba(254, 199, 34, 0.1);
	    z-index: 1;
	    content: "";
	}
	.courses .courses-area-04 .courses-wrapper .single-courses figure figcaption {
	    position: absolute;
	    text-align: center;
	    top: 0;
	    transform: translateY(-50%);
	    width: 100%;
	    z-index: 100;
	    visibility: hidden;
	    transition: all 0.3s ease-in-out;
	    opacity: 0;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses figure figcaption div a {
	    background: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    border-radius: 0;
	    color: #333333;
	    display: inline-block;
	    font-size: 15px;
	    padding: 10px 30px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses figure:before {
	    background: rgba(0, 0, 0, 0.4);
	    content: "";
	    height: 0%;
	    opacity: 0;
	    position: absolute;
	    width: 100%;
	    z-index: 1;
	    visibility: hidden;
	    top: 0;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses:hover figcaption {
	    visibility: visible;
	    top: 50%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.15);
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box {
	    padding: 25px 20px;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content h3 {
	    margin: 0;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content h3 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 700;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content ul {
	    margin: 0;
	    padding-bottom: 15px;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content ul li {
	    display: table-cell;
	    font-size: 15px;
	    color: #666666;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content ul li span {
	    text-transform: uppercase;
	    font-weight: 700;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content ul li:first-child {
	    padding-right: 20px;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .rank-icons ul {
	    display: inline-block;
	    padding: 5px 0;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .rank-icons ul li {
	    display: inline-block;
	    color: #ec7a11;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .rank-icons span {
	    padding-left: 15px;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul {
	    border-top: 2px solid #ebebeb;
	    padding-top: 15px;
	    margin-bottom: 0;
	    text-align: right;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul li {
	    display: inline-block;
	    text-align: right;
	    color: #b2b2b2;
	    font-size: 16px;
	    padding-left: 10px;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses .courses-content-box .courses-content-bottom ul li:first-child {
	    color: #b2b2b2;
	    float: left;
	    font-family: 'Montserrat', sans-serif;
	    font-size: 15px;
	    font-weight: 600;
	    padding-left: 0px;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses:hover .courses-content-box .courses-content h3 a {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .single-courses:hover figure::before {
	    visibility: visible;
	    height: 100%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .owl-prev,
	.courses .courses-area-04 .courses-wrapper .owl-next {
	    border: 1px solid #333333;
	    border-radius: 0px;
	    color: #333333;
	    display: inline-block;
	    font-size: 15px;
	    height: 33px;
	    position: absolute;
	    text-align: center;
	    top: -33px;
	    transition: all 0.3s ease-in-out;
	    width: 33px;
	}
	.courses .courses-area-04 .courses-wrapper .owl-prev:hover,
	.courses .courses-area-04 .courses-wrapper .owl-next:hover {
	    border: 1px solid #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .owl-prev:hover .fa-angle-left,
	.courses .courses-area-04 .courses-wrapper .owl-prev:hover .fa-angle-right,
	.courses .courses-area-04 .courses-wrapper .owl-next:hover .fa-angle-left,
	.courses .courses-area-04 .courses-wrapper .owl-next:hover .fa-angle-right {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .owl-prev {
	    right: 55px;
	}
	.courses .courses-area-04 .courses-wrapper .owl-prev .fa-angle-left {
	    color: #333333;
	    font-size: 18px;
	    line-height: 23px;
	    transition: all 0.3s ease-in-out;
	}
	.courses .courses-area-04 .courses-wrapper .owl-next {
	    right: 18px;
	}
	.courses .courses-area-04 .courses-wrapper .owl-next .fa-angle-right {
	    color: #333333;
	    font-size: 18px;
	    line-height: 23px;
	    transition: all 0.3s ease-in-out;
	}
	/*====================================================================================================
										===[ Blog Page ]===
=====================================================================================================*/
	
	.blog_1 header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.blog_1 header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.blog_1 header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.blog_1 header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.blog_1 header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.blog_1 header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.blog_1 header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area {
	    padding: 100px 0;
	    min-height: 1000px;
	    background: #ffffff;
	    /*===[ Blog Right Side Area Style ]====*/
	}
	.blog_1 .blog-area .blog-padding-none {
	    padding: 0;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box {
	    margin-bottom: 50px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box:last-child {
	    margin-bottom: 0;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item {
	    overflow: hidden;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item:last-child {
	    margin-bottom: 0;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .img-box {
	    position: relative;
	    overflow: hidden;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .img-box img {
	    width: 100%;
	    transition: all 0.4s ease-in-out;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .img-box .overlay {
	    background: #000000;
	    opacity: 0;
	    content: "";
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    transition: all 0.4s ease-in-out;
	    visibility: hidden;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .img-box .img-caption {
	    position: absolute;
	    top: 0;
	    left: 0;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .img-box .img-caption .date {
	    background: #ec7a11;
	    color: #333;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .img-box .img-caption p {
	    background: #fafafa;
	    text-align: center;
	    margin: 0;
	    height: 50px;
	    width: 60px;
	    line-height: 15px;
	    padding: 9px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .img-box .img-caption p span {
	    display: block;
	    font-size: 20px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .img-box .img-caption p span:last-child {
	    padding-top: 3px;
	    font-size: 13px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box {
	    background: #fafafa;
	    padding: 35px 35px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box h3 {
	    font-size: 20px;
	    padding: 0;
	    margin-bottom: 10px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box h3 a {
	    transition: all 0.3s ease-in-out;
	    color: #323232;
	    text-decoration: none;
	    font-size: 28px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box h3 .blog-btn-box:hover {
	    background: #ec7a11;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box ul {
	    margin-bottom: 20px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box ul li {
	    display: inline-block;
	    padding-right: 25px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box ul li a {
	    color: #666666;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 12px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box ul li a i {
	    margin-right: 10px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box p {
	    margin: 0;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box .blog-btn-box {
	    background: #eee;
	    border-radius: 0px;
	    height: 42px;
	    width: 120px;
	    text-align: center;
	    margin-top: 30px;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box:hover .blog-btn-box {
	    background: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item .single-text-box .blog-btn-box a {
	    color: #333333;
	    font-size: 14px;
	    font-weight: 600;
	    line-height: 42px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item:hover .img-box img {
	    transform: scale(1.1);
	}
	.blog_1 .blog-area .bolg_side-left .single-item-box .single-item:hover .overlay {
	    top: 0px;
	    opacity: 0.5;
	    visibility: visible;
	    transition: all 0.4s ease-in-out;
	}
	.blog_1 .blog-area .bolg_side-left .blog-comment {
	    background: #fafafa;
	    padding: 30px 35px 30px 65px;
	}
	.blog_1 .blog-area .bolg_side-left .blog-comment p {
	    font-size: 17px;
	    position: relative;
	    margin: 0;
	    padding-bottom: 25px;
	}
	.blog_1 .blog-area .bolg_side-left .blog-comment p:before {
	    position: absolute;
	    font-family: FontAwesome;
	    content: "\f10d";
	    top: 0;
	    left: -30px;
	    font-size: 25px;
	    opacity: 0.5;
	}
	.blog_1 .blog-area .bolg_side-left .blog-comment span {
	    position: relative;
	    color: #ec7a11;
	    font-size: 17px;
	    padding-left: 25px;
	}
	.blog_1 .blog-area .bolg_side-left .blog-comment span:before {
	    position: absolute;
	    top: 12px;
	    left: 0px;
	    height: 1px;
	    width: 15px;
	    background: #ec7a11;
	    content: "";
	}
	.blog_1 .blog-area .bolg_side-left .pagination {
	    margin: 0px;
	}
	.blog_1 .blog-area .bolg_side-left .pagination li:first-child a {
	    border-radius: 0px;
	    margin-right: 20px;
	}
	.blog_1 .blog-area .bolg_side-left .pagination li:last-child a {
	    border-radius: 0px;
	    margin-left: 20px;
	}
	.blog_1 .blog-area .bolg_side-left .pagination li.active a {
		background: #ec7a11;
		border: 1px solid #ec7a11;
		color: #333;
	}
	.blog_1 .blog-area .bolg_side-left .pagination li a {
	    margin: 0 5px;
	    color: #333333;
	}
	.blog_1 .blog-area .bolg_side-left .pagination li a:focus,
	.blog_1 .blog-area .bolg_side-left .pagination li a:hover {
	    z-index: 2;
	    color: #333;
	    background: #ec7a11;
	    border-color: #ddd;
	}
	.blog_1 .blog-area .blog_side-right .categories-item {
	    margin-bottom: 40px;
	}
	.blog_1 .blog-area .blog_side-right .categories-item ul {
	    margin: 0;
	}
	.blog_1 .blog-area .blog_side-right .categories-item ul li {
	    margin-bottom: 20px;
	    margin-left: 10px;
	}
	.blog_1 .blog-area .blog_side-right .categories-item ul li:last-child {
	    margin-bottom: 0;
	}
	.blog_1 .blog-area .blog_side-right .categories-item ul li a {
	    color: #666666;
	    text-decoration: none;
	}
	.blog_1 .blog-area .blog_side-right .categories-item ul li a i {
	    margin-right: 25px;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area .blog_side-right .categories-item ul li a span {
	    margin-left: 15px;
	    color: #ec7a11;
	}
	.blog_1 .blog-area .blog_side-right .categories-item ul li a:hover i {
	    color: #ec7a11;
	    padding-left: 5px;
	}
	.blog_1 .blog-area .blog_side-right .social-icon {
	    margin-bottom: 40px;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul {
	    margin: 0;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li {
	    display: inline-block;
	    margin: 0 9px;
	    margin-bottom: 10px;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li:last-child {
	    margin-bottom: 0;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li a {
	    background: red none repeat scroll 0 0;
	    color: #ffffff;
	    display: block;
	    height: 38px;
	    width: 160px;
	    padding-left: 10px;
	    padding-top: 9px;
	    font-size: 13px;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li a i {
	    margin-right: 15px;
	    height: 25px;
	    width: 25px;
	    text-align: center;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li .rss {
	    background: #ffa801;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li .twitter {
	    background: #1da1f2;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li .facebook {
	    background: #3b59a1;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li .pinterest {
	    background: #bd081c;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li .instagram {
	    background: #f56040;
	}
	.blog_1 .blog-area .blog_side-right .social-icon ul li .google {
	    background: #dd4b39;
	}
	.blog_1 .blog-area .blog_side-right .recent-work {
	    margin-bottom: 40px;
	}
	.blog_1 .blog-area .blog_side-right .recent-work h3 {
	    text-align: left;
	}
	.blog_1 .blog-area .blog_side-right .recent-work .row-padding-bottom img {
	    width: 100px;
	    float: left;
	    margin: 10px;
	}
	.blog_1 .blog-area .blog_side-right .recent-work .owl-prev,
	.blog_1 .blog-area .blog_side-right .recent-work .owl-next {
	    border: 1px solid #333333;
	    border-radius: 0px;
	    color: #fff;
	    display: inline-block;
	    font-size: 15px;
	    height: 33px;
	    position: absolute;
	    text-align: center;
	    top: -55px;
	    transition: all 0.3s ease-in-out;
	    width: 33px;
	}
	.blog_1 .blog-area .blog_side-right .recent-work .owl-prev:hover,
	.blog_1 .blog-area .blog_side-right .recent-work .owl-next:hover {
	    border: 1px solid #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area .blog_side-right .recent-work .owl-prev:hover .fa-angle-left,
	.blog_1 .blog-area .blog_side-right .recent-work .owl-prev:hover .fa-angle-right,
	.blog_1 .blog-area .blog_side-right .recent-work .owl-next:hover .fa-angle-left,
	.blog_1 .blog-area .blog_side-right .recent-work .owl-next:hover .fa-angle-right {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area .blog_side-right .recent-work .owl-prev {
	    right: 38px;
	}
	.blog_1 .blog-area .blog_side-right .recent-work .owl-prev .fa-angle-left {
	    color: #333333;
	    font-size: 18px;
	    line-height: 24px;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area .blog_side-right .recent-work .owl-next {
	    right: 0px;
	}
	.blog_1 .blog-area .blog_side-right .recent-work .owl-next .fa-angle-right {
	    color: #333333;
	    font-size: 18px;
	    line-height: 24px;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 {
	    margin-bottom: 40px;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single {
	    margin-bottom: 30px;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single:last-child {
	    margin-bottom: 0;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single .recent-content-item .img-box {
	    width: 30%;
	    float: left;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single .recent-content-item a img {
	    height: 80px;
	    width: 80px;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single .recent-content-item .recent-text {
	    width: 70%;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single .recent-content-item .recent-text a {
	    text-decoration: none;
	    color: #666666;
	    font-size: 16px;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single .recent-content-item .recent-text a:hover {
	    color: #ec7a11;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single .recent-content-item .recent-text p {
	    font-size: 13px;
	    margin: 0;
	    padding-top: 10px;
	    color: #ec7a11;
	    text-align: right;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single .recent-content-item .recent-text p .content {
	    float: left;
	}
	.blog_1 .blog-area .blog_side-right .recent-post-01 .recent-single .recent-content-item .recent-text p .content i {
	    padding-right: 6px;
	}
	.blog_1 .blog-area .blog_side-right .populer-tags {
	    margin-bottom: 40px;
	}
	.blog_1 .blog-area .blog_side-right .populer-tags .tagcloud a {
	    color: #666666;
	    font-size: 13px;
	    border: none;
	    border-radius: 0px;
	    font-weight: 600;
	    border: 1px solid #ec7a11;
	    padding: 8px 15px;
	    float: left;
	    margin: 0 10px 10px 0;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.blog_1 .blog-area .blog_side-right .populer-tags .tagcloud a:hover {
	    background: #ec7a11;
	    color: #ffffff;
	}
	/*====================================================================================================
										===[ Blog Post ]===
=====================================================================================================*/
	
	.post-1 header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.post-1 header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.post-1 header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.post-1 header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.post-1 header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.post-1 header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.post-1 header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area {
	    padding: 100px 0;
	    min-height: 1000px;
	    background: #ffffff;
	}
	.post-1 .post_1_area .padding-left {
	    padding-left: 0;
	}
	.post-1 .post_1_area .padding-right {
	    padding-right: 0;
	}
	.post-1 .post_1_area .post-col-paddign {
	    padding: 0;
	}
	.post-1 .post_1_area .post_left-side .post-img-box img {
	    width: 100%;
	    height: auto;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-heading {
	    background: #fafafa;
	    margin-bottom: 40px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-heading .time {
	    background: #ec7a11;
	    width: 70px;
	    height: 70px;
	    float: left;
	    margin-right: 25px;
	    padding: 15px 0;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-heading .time span {
	    display: block;
	    color: #ffffff;
	    text-align: center;
	    font-size: 15px;
	    font-weight: 500;
	    line-height: 20px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-heading h3 {
	    min-height: 70px;
	    padding: 0;
	    font-size: 28px;
	    padding-top: 24px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text {
	    margin-bottom: 40px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left {
	    text-align: center;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left .author-img {
	    margin-bottom: 15px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left .author-img .author-details a {
	    color: #666666;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	    font-size: 13px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left .author-img .author-details a:hover {
	    color: #ec7a11;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul {
	    margin-bottom: 25px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li {
	    margin-bottom: 15px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li:last-child {
	    margin-bottom: 0;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li i {
	    width: 100%;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul {
	    margin-bottom: 25px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li {
	    margin-bottom: 15px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li:last-child {
	    margin-bottom: 0;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li a {
	    width: 30px;
	    height: 30px;
	    text-align: center;
	    padding: 8px 0px 0px;
	    color: #ffffff;
	    text-decoration: none;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li .facebook {
	    background: #3a589b;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li .google {
	    background: #00aced;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li .twitter {
	    background: #3a589b;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-side-left ul li .linkedin {
	    background: #0077b5;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-text-right p {
	    margin-bottom: 30px;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-text-right p:last-child {
	    margin-bottom: 0;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-text-right .description-speech {
	    text-align: center;
	    border: 1px solid #d7d7d7;
	    padding: 40px;
	    font-size: 18px;
	    font-style: italic;
	}
	.post-1 .post_1_area .post_left-side .description-content .description-text .description-text-right .description-speech i {
	    display: block;
	    font-size: 18px;
	    height: 40px;
	    width: 40px;
	    background: #ec7a11;
	    text-align: center;
	    border-radius: 50%;
	    color: #ffffff;
	    line-height: 40px;
	    margin: -60px auto 25px;
	}
	.post-1 .post_1_area .post_left-side .description-content .tag-links-box {
	    padding: 15px 30px;
	    border: 1px solid #d7d7d7;
	    margin-top: 50px;
	}
	.post-1 .post_1_area .post_left-side .description-content .tag-links-box p {
	    font-size: 18px;
	    font-weight: 600;
	    margin-right: 10px;
	    float: left;
	}
	.post-1 .post_1_area .post_left-side .description-content .tag-links-box ul {
	    margin: 0;
	}
	.post-1 .post_1_area .post_left-side .description-content .tag-links-box ul li {
	    display: inline-block;
	    margin: 5px 3px;
	}
	.post-1 .post_1_area .post_left-side .description-content .tag-links-box ul li:last-child {
	    float: right;
	}
	.post-1 .post_1_area .post_left-side .description-content .tag-links-box ul li:last-child a {
	    color: #666666;
	    padding: 4px 15px;
	    background: #ec7a11;
	    border-radius: 0px;
	}
	.post-1 .post_1_area .post_left-side .description-content .tag-links-box ul li a {
	    color: #ec7a11;
	    padding: 2px 10px;
	    background: #fafafa;
	    text-decoration: none;
	    border-radius: 3px;
	}
	.post-1 .post_1_area .post_left-side .prev_next .text-box {
	    padding: 30px 30px;
	}
	.post-1 .post_1_area .post_left-side .prev_next .text-box p {
	    font-size: 16px;
	    margin-bottom: 15px;
	}
	.post-1 .post_1_area .post_left-side .prev_next .text-box a {
	    border: 1px solid #d7d7d7;
	    text-align: center;
	    padding: 5px 15px;
	    color: #666666;
	    text-decoration: none;
	    font-size: 13px;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .prev_next .text-box:hover a {
	    background: #ec7a11;
	    border: 1px solid #ec7a11;
	}
	.post-1 .post_1_area .post_left-side .prev_next .prev {
	    text-align: right;
	    border-top: 1px solid #d7d7d7;
	    border-left: 1px solid #d7d7d7;
	    width: 100%;
	    position: relative;
	    padding: 30px 30px;
	}
	.post-1 .post_1_area .post_left-side .prev_next .prev:before {
	    background-color: #d7d7d7;
	    content: "";
	    height: 1px;
	    left: 0;
	    position: absolute;
	    bottom: 0px;
	    width: 95%;
	}
	.post-1 .post_1_area .post_left-side .prev_next .prev:after {
	    background-color: #d7d77d;
	    top: -1px;
	    content: "";
	    height: 101%;
	    right: 10px;
	    position: absolute;
	    width: 1px;
	    transform: rotate(10deg);
	}
	.post-1 .post_1_area .post_left-side .prev_next .next {
	    text-align: left;
	    border-right: 1px solid #d7d7d7;
	    border-bottom: 1px solid #d7d7d7;
	    position: relative;
	}
	.post-1 .post_1_area .post_left-side .prev_next .next:before {
	    background-color: #d7d7d7;
	    content: "";
	    height: 1px;
	    right: 0px;
	    position: absolute;
	    top: 0px;
	    width: 95%;
	}
	.post-1 .post_1_area .post_left-side .prev_next .next:after {
	    background-color: #d7d77d;
	    top: -1px;
	    content: "";
	    height: 101%;
	    left: 10px;
	    position: absolute;
	    width: 1px;
	    transform: rotate(10deg);
	}
	.post-1 .post_1_area .post_left-side .post_slider {
	    margin: 40px 0;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    position: relative;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post:before {
	    position: absolute;
	    bottom: 0;
	    right: 0;
	    width: 0;
	    height: 0;
	    border-bottom: 20px solid #d7d7d7;
	    border-left: 20px solid transparent;
	    content: "";
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post figure {
	    position: relative;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post figure .figure-img {
	    position: relative;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post figure .figure-img img {
	    width: 100%;
	    height: auto;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post figure .figure-img::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100%;
	    width: 100%;
	    background: rgba(254, 199, 34, 0.1);
	    z-index: 1;
	    content: "";
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post figure figcaption {
	    position: absolute;
	    text-align: center;
	    top: 0;
	    transform: translateY(-50%);
	    width: 100%;
	    z-index: 100;
	    visibility: hidden;
	    transition: all 0.3s ease-in-out;
	    opacity: 0;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post figure figcaption div a {
	    background: #ec7a11;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    border-radius: 0;
	    color: #333333;
	    display: inline-block;
	    font-size: 15px;
	    padding: 8px 20px;
	    text-transform: uppercase;
	    text-decoration: none;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post figure:before {
	    background: rgba(0, 0, 0, 0.4);
	    content: "";
	    height: 0%;
	    opacity: 0;
	    position: absolute;
	    width: 100%;
	    z-index: 1;
	    visibility: hidden;
	    top: 0;
	    left: 0;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post:hover figcaption {
	    visibility: visible;
	    top: 50%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post .courses-content-box {
	    padding: 25px;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post .courses-content-box .courses-content h4 {
	    margin-bottom: 15px;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post .courses-content-box .courses-content h4 a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: none;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 18px;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post:hover .courses-content-box .courses-content h3 a {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post:hover:before {
	    border-bottom: 25px solid #ec7a11;
	    border-left: 25px solid transparent;
	}
	.post-1 .post_1_area .post_left-side .post_slider .blog-post:hover figure::before {
	    visibility: visible;
	    height: 100%;
	    opacity: 1;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .comments {
	    margin-top: 40px;
	    padding: 0 30px;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item {
	    margin-bottom: 10px;
	    background: #fafafa;
	    padding: 30px 25px;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item:last-child {
	    margin-bottom: 0;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .img-box {
	    padding: 0;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .img-box img {
	    height: 65px;
	    width: 65px;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .comment-left-bar .comment-text {
	    padding-left: 40px;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .comment-left-bar .comment-text .comment-author-box li {
	    display: inline-block;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .comment-left-bar .comment-text .comment-author-box li span {
	    display: flex;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .comment-left-bar .comment-text .comment-author-box li .name {
	    font-size: 15px;
	    font-weight: 600;
	    padding-bottom: 6px;
	    text-transform: uppercase;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .comment-left-bar .comment-text .comment-author-box .reply {
	    float: right;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .comment-left-bar .comment-text .comment-author-box .reply a {
	    color: #666666;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .comment-left-bar .comment-text .comment-author-box .reply a:hover {
	    color: #ec7a11;
	}
	.post-1 .post_1_area .post_left-side .comments .comment-single-item .comment-left-bar .comment-text p {
	    margin: 0;
	    padding-top: 10px;
	}
	.post-1 .post_1_area .post_left-side .comments .reply_text {
	    width: 90%;
	    float: right;
	}
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond {
	    margin-top: 50px;
	}
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond .comment-reply-title h3 {
	    color: #333333;
	    display: inline-block;
	    font-size: 20px;
	    margin-bottom: 15px;
	    padding-bottom: 15px;
	    position: relative;
	}
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond .comment-reply-title h3:before {
	    position: absolute;
	    left: 0;
	    bottom: -1px;
	    content: "";
	    width: 50%;
	    height: 2px;
	    content: "";
	    background: #333333;
	}
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond label {
	    font-size: 15px;
	    color: #454545;
	    font-style: italic;
	}
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond input {
	    height: 40px;
	}
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond input,
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond textarea {
	    background-color: #f9f9f9;
	    border-radius: 0;
	    box-shadow: none;
	    font-size: 15px;
	    margin: 10px 0;
	    padding: 10px 20px;
	    outline: none;
	}
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond input:focus,
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond textarea:focus {
	    border: 1px solid #ec7a11;
	}
	.post-1 .post_1_area .post_left-side .leave-comment-box .comment-respond input[type="submit"] {
	    background: #ec7a11;
	    border-radius: 0;
	    color: #fff;
	    border: none;
	    font-size: 15px;
	    font-weight: 500;
	    margin-top: 20px;
	    height: 40px;
	    text-transform: uppercase;
	}
	.post-1 .sidebar-text-post {
	    width: 85%;
	    float: right;
	}
	.post-1 .sidebar-text-post .sidebar-left-sec-padding {
	    padding: 0;
	}
	.post-1 .sidebar-text-post .recent-post {
	    margin-bottom: 40px;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single {
	    margin-bottom: 30px;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single:last-child {
	    margin-bottom: 0;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single .recent-content-item .img-box {
	    width: 30%;
	    float: left;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single .recent-content-item a img {
	    height: 80px;
	    width: 80px;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single .recent-content-item .recent-text {
	    width: 70%;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single .recent-content-item .recent-text a {
	    text-decoration: none;
	    color: #666666;
	    font-size: 16px;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single .recent-content-item .recent-text a:hover {
	    color: #ec7a11;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single .recent-content-item .recent-text p {
	    font-size: 13px;
	    margin: 0;
	    padding-top: 10px;
	    color: #ec7a11;
	    text-align: right;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single .recent-content-item .recent-text p .content {
	    float: left;
	}
	.post-1 .sidebar-text-post .recent-post .recent-single .recent-content-item .recent-text p .content i {
	    padding-right: 6px;
	}
	.post-1 .sidebar-text-post .categories-item-post {
	    margin-bottom: 40px;
	}
	.post-1 .sidebar-text-post .categories-item-post ul {
	    margin: 0;
	}
	.post-1 .sidebar-text-post .categories-item-post ul li {
	    margin-bottom: 15px;
	}
	.post-1 .sidebar-text-post .categories-item-post ul li:last-child {
	    margin-bottom: 0;
	}
	.post-1 .sidebar-text-post .categories-item-post ul li a {
	    color: #666666;
	    text-decoration: none;
	}
	.post-1 .sidebar-text-post .categories-item-post ul li a i {
	    margin-right: 25px;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .sidebar-text-post .categories-item-post ul li a span {
	    margin-left: 15px;
	    color: #ec7a11;
	}
	.post-1 .sidebar-text-post .categories-item-post ul li a:hover i {
	    color: #ec7a11;
	    padding-left: 5px;
	}
	.post-1 .sidebar-text-post .populer-tags {
	    margin-bottom: 40px;
	}
	.post-1 .sidebar-text-post .populer-tags .tagcloud a {
	    color: #666666;
	    font-size: 13px;
	    border: none;
	    border-radius: 0px;
	    font-weight: 600;
	    border: 1px solid #ec7a11;
	    padding: 8px 15px;
	    float: left;
	    margin: 0 10px 10px 0;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.post-1 .sidebar-text-post .populer-tags .tagcloud a:hover {
	    background: #ec7a11;
	    color: #ffffff;
	}
	/*====================================================================================================
										===[ Teachers Page ]===
=====================================================================================================*/
	
	.teachers-01 header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.teachers-01 header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.teachers-01 header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.teachers-01 header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.teachers-01 header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.teachers-01 header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.teachers-01 header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.teachers-01 header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.teachers-01 .teachers-area {
	    padding: 100px 0;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 {
	    margin-bottom: 50px;
	}
	.teachers-01 .teachers-area .teachers-wapper-01:last-child {
	    margin-bottom: 0;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body img {
	    width: 100%;
	    min-height: 290px;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body .teachars-info {
	    text-align: center;
	    padding: 25px 20px 12px;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body .teachars-info h3 {
	    padding: 0;
	    font-size: 20px;
	    margin-bottom: 4px;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body .teachars-info span {
	    font-size: 14px;
	    font-family: 'Montserrat', sans-serif;
	    text-transform: uppercase;
	    color: #727272;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body .teachars-info ul {
	    margin-top: 20px;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body .teachars-info ul li {
	    margin: 0 5px;
	    display: inline-block;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body .teachars-info ul li .teacher-icon {
	    background: #f9f9f9;
	    border-radius: 100%;
	    color: #a7a7a7;
	    height: 30px;
	    padding: 7px;
	    width: 30px;
	    transition: all 0.3s ease-in-out;
	}
	.teachers-01 .teachers-area .teachers-wapper-01 .teacher-body .teachars-info ul li .teacher-icon:hover {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    background: #ec7a11;
	    color: #ffffff;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 {
	    margin-bottom: 50px;
	}
	.teachers-01 .teachers-area .teachers-wapper-02:last-child {
	    margin-bottom: 0;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    position: relative;
	    width: 100%;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure:hover .teacher-img-02:before {
	    height: 100%;
	    visibility: visible;
	    opacity: 1;
	}

	.teachers-01 .teachers-area .teachers-wapper-02 figure:hover figcaption {
	    bottom: 50%;
	    visibility: visible;
	    opacity: 1;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure .teacher-img-02 {
	    position: relative;
	    height: 290px;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure .teacher-img-02:before {
	    background: rgba(17, 17, 17, 0.6);
	    content: "";
	    left: 0;
	    position: absolute;
	    top: 0;
	    height: 0;
	    width: 100%;
	    transition: all 0.3s ease 0s;
	    visibility: hidden;
	    opacity: 0;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure .teacher-img-02 img {
	    width: 100%;
	    height: 100%;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure .teachers-name {
	    padding: 25px 15px;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure .teachers-name h3 {
	    padding-bottom: 5px;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure .teachers-name h3 a {
	    padding: 0;
	    font-size: 20px;
	    margin-bottom: 10px;
	    color: #333333;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure .teachers-name span {
	    font-size: 14px;
	    font-family: 'Montserrat', sans-serif;
	    text-transform: uppercase;
	    color: #727272;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure figcaption {
	    text-align: center;
	    position: absolute;
	    bottom: 20px;
	    left: 0;
	    width: 100%;
	    opacity: 0;
	    visibility: hidden;
	    transition: all 0.3s ease-in-out;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure figcaption ul {
	    margin: 10px auto;
	    text-align: center;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure figcaption ul li {
	    display: inline-flex;
	    margin: 0px 2px;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure figcaption ul li a {
	    background: #f9f9f9;
	    border-radius: 100%;
	    color: #a7a7a7;
	    display: block;
	    font-size: 17px;
	    height: 35px;
	    line-height: 35px;
	    text-align: center;
	    transition: all 0.4s ease 0s;
	    width: 35px;
	}
	.teachers-01 .teachers-area .teachers-wapper-02 figure figcaption ul li a:hover {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    background: #ec7a11;
	    color: #ffffff;
	}
	/*====================================================================================================
										===[ Teachers profile ]===
=====================================================================================================*/
	
	.t-profile-01 header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.t-profile-01 header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.t-profile-01 header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.t-profile-01 header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.t-profile-01 header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.t-profile-01 header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.t-profile-01 header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.t-profile-01 header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.t-profile-01 .teacher-prolile-01 {
	    padding: 100px 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-left .teacher-contact h3 {
	    padding: 25px 0px 10px;
	    position: relative;
	    margin-bottom: 10px;
	    border-bottom: 1px solid #d7d7d7;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-left .teacher-contact h3:before {
	    position: absolute;
	    bottom: -1px;
	    left: 0;
	    height: 1px;
	    width: 25%;
	    background: #ec7a11;
	    content: "";
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-left .teacher-contact p {
	    margin: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-left .teacher-contact ul {
	    margin: 25px auto;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-left .teacher-contact ul li {
	    display: inline-flex;
	    margin: 0px 2px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-left .teacher-contact ul li a {
	    background: #f9f9f9;
	    border-radius: 100%;
	    color: #a7a7a7;
	    display: block;
	    font-size: 17px;
	    height: 35px;
	    line-height: 35px;
	    text-align: center;
	    transition: all 0.4s ease 0s;
	    width: 35px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-left .teacher-contact ul li a:hover {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    background: #ec7a11;
	    color: #ffffff;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses h3 {
	    padding: 0px 0px 10px;
	    position: relative;
	    margin-bottom: 15px;
	    border-bottom: 1px solid #d7d7d7;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses h3:before {
	    position: absolute;
	    bottom: -1px;
	    left: 0;
	    height: 1px;
	    width: 15%;
	    background: #ec7a11;
	    content: "";
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses .profile__courses__inner {
	    display: flex;
	    margin-bottom: 40px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses .profile__courses__inner .profile__courses__list {
	    min-width: 140px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses .profile__courses__inner ul {
	    margin: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses .profile__courses__inner ul li {
	    margin-bottom: 15px;
	    color: #333;
	    font-size: 15px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses .profile__courses__inner ul li:last-child {
	    margin-bottom: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses .profile__courses__inner ul li i {
	    color: #ec7a11;
	    font-size: 16px;
	    margin-right: 10px;
	    width: 20px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses p {
	    margin-bottom: 25px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .all-corsess-wapper .all-courses p:last-child {
	    margin-bottom: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill {
	    margin-top: 40px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill h3 {
	    padding: 25px 0px 10px;
	    position: relative;
	    margin-bottom: 25px;
	    border-bottom: 1px solid #d7d7d7;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill h3:before {
	    position: absolute;
	    bottom: -1px;
	    left: 0;
	    height: 1px;
	    width: 25%;
	    background: #ec7a11;
	    content: "";
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress-bar-linear {
	    margin-top: 15px;
	    margin-bottom: 15px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress-bar-linear .progress-bar-text {
	    display: inline-block;
	    margin-left: 8px;
	    margin-bottom: 6px;
	    font-size: 15px;
	    font-weight: 700;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress-bar-linear .progress-cont {
	    display: inline-block;
	    float: right;
	    margin-right: 10px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress-bar-linear .progress-bar-skills {
	    position: relative;
	    width: 100%;
	    background-color: #efefef;
	    -webkit-border-radius: 12px;
	    -moz-border-radius: 12px;
	    border-radius: 12px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress-bar-linear .progress-bar-line {
	    position: relative;
	    width: 5%;
	    height: 6px;
	    -webkit-transition: all 1.6s ease-out;
	    -o-transition: all 1.6s ease-out;
	    -moz-transition: all 1.6s ease-out;
	    transition: all 1.6s ease-out;
	    -webkit-border-radius: 12px;
	    -moz-border-radius: 12px;
	    border-radius: 12px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress_bar-2 h3 {
	    padding: 25px 0px 10px;
	    position: relative;
	    margin-bottom: 25px;
	    border-bottom: 1px solid #d7d7d7;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress_bar-2 h3:before {
	    position: absolute;
	    bottom: -1px;
	    left: 0;
	    height: 1px;
	    width: 25%;
	    background: #ec7a11;
	    content: "";
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress_bar-2 .single_skill .progress {
	    border-radius: 3px;
	    box-shadow: none;
	    height: 6px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_skill .progress_bar-2 .single_skill .progress .progress-bar {
	    border-radius: 10px;
	    background: #ec7a11;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_shedule_list h3 {
	    padding: 25px 0px 10px;
	    position: relative;
	    margin-bottom: 25px;
	    border-bottom: 1px solid #d7d7d7;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_shedule_list h3:before {
	    position: absolute;
	    bottom: -1px;
	    left: 0;
	    height: 1px;
	    width: 25%;
	    background: #ec7a11;
	    content: "";
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_shedule_list ul {
	    margin: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_shedule_list ul li {
	    color: #333333;
	    font-size: 14px;
	    text-transform: capitalize;
	    margin-bottom: 20px;
	    border-bottom: 1px solid #d7d7d7;
	    padding-bottom: 8px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_shedule_list ul li:last-child {
	    margin-bottom: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .teacher_shedule_list ul li span {
	    color: #333333;
	    float: right;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor {
	    margin-top: 40px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .courses-title {
	    padding: 25px 0px 10px;
	    position: relative;
	    margin-bottom: 25px;
	    border-bottom: 1px solid #d7d7d7;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .courses-title:before {
	    position: absolute;
	    bottom: -1px;
	    left: 0;
	    height: 1px;
	    width: 30%;
	    background: #ec7a11;
	    content: "";
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .item-margin {
	    margin-bottom: 30px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .item-margin:last-child {
	    margin-bottom: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    z-index: 2;
	    background: #ffffff;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single:hover h3 a {
	    text-decoration: none;
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single:hover img {
	    transform: scale(1.1);
	    transition: all 0.3s ease-in-out;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .img-box {
	    overflow: hidden;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .img-box img {
	    width: 100%;
	    transition: all 0.3s ease-in-out;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .instractor-parson {
	    margin-top: -60px;
	    background: #000;
	    opacity: 0.8;
	    align-items: center;
	    display: flex;
	    height: 60px;
	    padding: 0px 20px;
	    position: relative;
	    z-index: 2;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .instractor-parson .parson-img {
	    height: 45px;
	    width: 45px;
	    float: left;
	    margin-right: 25px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .instractor-parson .parson-img img {
	    width: 100%;
	    transition: all 0.3s ease-in-out;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .instractor-parson p {
	    color: #ffffff;
	    margin: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .instractor-parson p a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .instractor-parson p a:hover {
	    color: #ec7a11;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .instractor-parson p span {
	    font-size: 13px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .text-bottom {
	    padding: 30px 25px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .text-bottom h3 {
	    padding: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .text-bottom h3 a {
	    color: #333333;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .instractor-courses-text .text-bottom p {
	    padding-top: 15px;
	    margin: 0;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .price {
	    background: #fafafa;
	    padding: 15px 25px;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .price ul {
	    margin: 0;
	    text-align: left;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .price ul li {
	    display: inline-block;
	    color: #b2b2b2;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .price ul li:last-child {
	    float: right;
	    color: #ec7a11;
	    font-weight: 600;
	}
	.t-profile-01 .teacher-prolile-01 .t-profile-right .courses-instuctor .instractor-courses-single .price ul li i {
	    padding-right: 10px;
	}
	/*====================================================================================================
										===[ Become A Teachers ]===
=====================================================================================================*/
	
	.become_teachers header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.become_teachers header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.become_teachers header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.become_teachers header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.become_teachers header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.become_teachers header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.become_teachers header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.become_teachers header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.become_teachers .become-teachers-01 {
	    padding: 100px 0;
	}
	.become_teachers .become-teachers-01 .become-title {
	    margin-bottom: 50px;
	}
	.become_teachers .become-teachers-01 .become-title h2 {
	    padding-bottom: 10px;
	}
	.become_teachers .become-teachers-01 .become-title p {
	    font-size: 16px;
	}
	.become_teachers .become-teachers-01 .become-title .line {
	    position: relative;
	}
	.become_teachers .become-teachers-01 .become-title .line:before {
	    position: absolute;
	    height: 2px;
	    width: 50px;
	    content: "";
	    background: #ec7a11;
	    bottom: 0;
	    left: -25px;
	    position: absolute;
	}
	.become_teachers .become-teachers-01 .become-title-text p {
	    margin: 0;
	    padding-bottom: 25px;
	}
	.become_teachers .become-teachers-01 .become-teacher-title {
	    margin-top: 100px;
	}
	.become_teachers .become-teachers-01 .contact-form {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	}
	.become_teachers .become-teachers-01 .contact-form h3 {
	    margin: 0;
	    padding: 25px 0;
	}
	.become_teachers .become-teachers-01 .contact-form p {
	    background: #d9edf7;
	    font-size: 13px;
	    padding: 10px;
	}
	.become_teachers .become-teachers-01 .contact-form p a {
	    color: #ec7a11;
	    text-decoration: none;
	}
	.become_teachers .become-teachers-01 .contact-form form {
	    width: 70%;
	    margin: 0px auto;
	    padding: 40px 0;
	}
	.become_teachers .become-teachers-01 .contact-form .form-group .form-control {
	    width: 330px;
	    max-width: 100%;
	    box-shadow: none;
	    border: 1px solid #eee;
	    line-height: 30px;
	    height: 40px;
	    padding: 5px 10px;
	    text-align: center;
	    color: #999;
	    border-radius: 0;
	}
	.become_teachers .become-teachers-01 .contact-form .submit-btn input {
	    background: #ec7a11;
	    text-transform: uppercase;
	    font-size: 13px;
	    font-weight: 700;
	    border: 0;
	    line-height: 30px;
	    height: 40px;
	    padding: 5px 25px;
	    box-shadow: none;
	}
	.become_teachers .become-teachers-01 .tab-wapper ul {
	    margin: 0;
	    border: none;
	    background: #fafafa;
	}
	.become_teachers .become-teachers-01 .tab-wapper ul li {
	    border: none;
	    width: 33.33%;
	}
	.become_teachers .become-teachers-01 .tab-wapper ul li.active a {
	    background: #ec7a11;
	    color: #fff;
	}
	.become_teachers .become-teachers-01 .tab-wapper ul li a {
	    color: #ec7a11;
	    border: none;
	    text-align: center;
	    background: #fafafa;
	    border-radius: 0px;
	    font-size: 15px;
	    font-weight: 600;
	    padding: 20px 15px;
	}
	.become_teachers .become-teachers-01 .tab-wapper ul li a i {
	    padding-right: 10px;
	}
	.become_teachers .become-teachers-01 .tab-wapper .tab-content .tab-pane {
	    padding: 60px 25px;
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	}
	.become_teachers .become-teachers-01 .tab-wapper .tab-content .tab-pane p {
	    margin: 0;
	    padding-bottom: 25px;
	}
	.become_teachers .become-teachers-01 .tab-wapper .tab-content .tab-pane p:last-child {
	    padding-bottom: 0;
	}
	/*====================================================================================================
										===[ event details ]===
=====================================================================================================*/
	
	.event-details-1 header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.event-details-1 header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.event-details-1 header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.event-details-1 header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 40px;
	    font-weight: 700;
	    color: #ffffff;
	    text-transform: uppercase;
	    margin: 0;
	}
	.event-details-1 header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.event-details-1 header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.event-details-1 header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .event-details {
	    padding: 100px 0;
	    min-height: 1000px;
	    background: #ffffff;
	}
	.event-details-1 .event-details .post_left-side .post-img-box {
	    position: relative;
	}
	.event-details-1 .event-details .post_left-side .post-img-box img {
	    width: 100%;
	    height: auto;
	}
	.event-details-1 .event-details .post_left-side .clock-countdown {
	    position: absolute;
	    top: 0;
	    left: 0;
	}
	.event-details-1 .event-details .post_left-side .clock-countdown .coundown-timer {
	    padding-left: 15px;
	}
	.event-details-1 .event-details .post_left-side .clock-countdown .coundown-timer .single-counter {
	    height: 60px;
	    width: 80px;
	    background: #ec7a11;
	    color: #ffffff;
	    font-weight: 600;
	    padding: 7px 0;
	}
	.event-details-1 .event-details .post_left-side .clock-countdown .coundown-timer .single-counter span {
	    display: block;
	    text-align: center;
	}
	.event-details-1 .event-details .post_left-side .clock-countdown .coundown-timer .single-counter span:first-child {
	    font-size: 22px;
	    line-height: 22px;
	}
	.event-details-1 .event-details .post_left-side .clock-countdown .coundown-timer .single-chag-color {
	    background: #fafafa;
	    color: #ec7a11;
	    font-weight: 600;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-heading {
	    margin-bottom: 40px;
	    border-bottom: 1px solid #fafafa;
	    padding-bottom: 15px;
	    position: relative;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-heading:before {
	    position: absolute;
	    height: 1px;
	    width: 25%;
	    bottom: -1px;
	    background: #ec7a11;
	    content: "";
	    left: 0;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-heading h3 {
	    padding-top: 30px;
	    padding-bottom: 15px;
	    margin: 0;
	    font-size: 22px;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-heading h3 a {
	    color: #333333;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-heading h3 a:hover {
	    color: #ec7a11;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-heading p {
	    margin: 0;
	    padding: 0;
	    color: #ec7a11;
	    font-size: 18px;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-heading p span:first-child {
	    margin-right: 50px;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-heading p span i {
	    margin-right: 10px;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text {
	    margin-bottom: 40px;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left {
	    text-align: center;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul {
	    margin-bottom: 25px;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li {
	    margin-bottom: 15px;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li:last-child {
	    margin-bottom: 0;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li a {
	    width: 30px;
	    height: 30px;
	    text-align: center;
	    padding: 8px 0px 0px;
	    color: #ffffff;
	    text-decoration: none;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li .facebook {
	    background: #3a589b;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li .google {
	    background: #00aced;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li .twitter {
	    background: #3a589b;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li .linkedin {
	    background: #0077b5;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li .instagram {
	    background: #3a589b;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li .youtube {
	    background: #cd201f;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-side-left ul li .pinterest {
	    background: #bd081c;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-text-right p {
	    margin-bottom: 30px;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-text-right p:last-child {
	    margin-bottom: 0;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-text-right .description-speech {
	    text-align: center;
	    border: 1px solid #d7d7d7;
	    padding: 40px;
	    font-size: 18px;
	    font-style: italic;
	}
	.event-details-1 .event-details .post_left-side .description-content .description-text .description-text-right .description-speech i {
	    display: block;
	    font-size: 18px;
	    height: 40px;
	    width: 40px;
	    background: #ec7a11;
	    text-align: center;
	    border-radius: 50%;
	    color: #ffffff;
	    line-height: 40px;
	    margin: -60px auto 25px;
	}
	.event-details-1 .event-details .post_left-side .more-events h3 {
	    padding: 0 15px 25px;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    z-index: 2;
	    background: #ffffff;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .img-box {
	    overflow: hidden;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .img-box img {
	    width: 100%;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box:hover img {
	    transform: scale(1.1);
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content {
	    padding: 20px 15px 30px;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content h4 {
	    margin: 0;
	    padding-bottom: 10px;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content h4 a {
	    margin: 0;
	    text-decoration: none;
	    color: #333333;
	    font-size: 16px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content h4 a:hover {
	    color: #ec7a11;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content .blog-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 25px;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content .blog-time span .event-icon {
	    padding-right: 2px;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content .content-bottom p {
	    padding: 15px 0;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content .content-bottom span {
	    display: inline-block;
	    text-align: right;
	    color: #b2b2b2;
	    font-size: 16px;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content .content-bottom span a {
	    color: #323232;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content .content-bottom span a i {
	    padding-left: 5px;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content .content-bottom span a:hover {
	    color: #ec7a11;
	}
	.event-details-1 .event-details .post_left-side .more-events .event-single-box .blog-content .content-bottom span a:hover i {
	    padding-left: 15px;
	}
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond {
	    margin-top: 50px;
	}
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond .comment-reply-title h3 {
	    color: #333333;
	    display: inline-block;
	    font-size: 20px;
	    margin-bottom: 15px;
	    padding-bottom: 15px;
	    position: relative;
	}
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond .comment-reply-title h3:before {
	    position: absolute;
	    left: 0;
	    bottom: -1px;
	    content: "";
	    width: 50%;
	    height: 2px;
	    content: "";
	    background: #333333;
	}
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond label {
	    font-size: 15px;
	    color: #454545;
	    font-style: italic;
	}
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond input {
	    height: 40px;
	}
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond input,
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond textarea {
	    background-color: #f9f9f9;
	    border-radius: 0;
	    box-shadow: none;
	    font-size: 15px;
	    margin: 10px 0 0;
	    padding: 10px 20px;
	    outline: none;
	}
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond input:focus,
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond textarea:focus {
	    border: 1px solid #ec7a11;
	}
	.event-details-1 .event-details .post_left-side .leave-comment-box .comment-respond input[type="submit"] {
	    background: #ec7a11;
	    border-radius: 0;
	    color: #fff;
	    border: none;
	    font-size: 15px;
	    font-weight: 500;
	    margin-top: 20px;
	    height: 40px;
	    text-transform: uppercase;
	}
	.event-details-1 .sidebar-text-post {
	    width: 85%;
	    float: right;
	}
	.event-details-1 .sidebar-text-post .sidebar-left-sec-padding {
	    padding: 0;
	}
	.event-details-1 .sidebar-text-post .categories-item-post {
	    margin-bottom: 40px;
	}
	.event-details-1 .sidebar-text-post .categories-item-post ul {
	    margin: 0;
	}
	.event-details-1 .sidebar-text-post .categories-item-post ul li {
	    margin-bottom: 15px;
	}
	.event-details-1 .sidebar-text-post .categories-item-post ul li:last-child {
	    margin-bottom: 0;
	}
	.event-details-1 .sidebar-text-post .categories-item-post ul li a {
	    color: #666666;
	    text-decoration: none;
	}
	.event-details-1 .sidebar-text-post .categories-item-post ul li a i {
	    margin-right: 25px;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .sidebar-text-post .categories-item-post ul li a span {
	    margin-left: 15px;
	    color: #ec7a11;
	}
	.event-details-1 .sidebar-text-post .categories-item-post ul li a:hover i {
	    color: #ec7a11;
	    padding-left: 5px;
	}
	.event-details-1 .sidebar-text-post .populer-tags {
	    margin-bottom: 40px;
	}
	.event-details-1 .sidebar-text-post .populer-tags .tagcloud a {
	    color: #666666;
	    font-size: 13px;
	    border: none;
	    border-radius: 0px;
	    font-weight: 600;
	    border: 1px solid #ec7a11;
	    padding: 8px 15px;
	    float: left;
	    margin: 0 10px 10px 0;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.event-details-1 .sidebar-text-post .populer-tags .tagcloud a:hover {
	    background: #ec7a11;
	    color: #ffffff;
	}
	/*====================================================================================================
										===[ Events Page ]===
=====================================================================================================*/
	
	.event-01 {
	    /*=======[ Up Comming Events ]========*/
	}
	.event-01 header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.event-01 header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.event-01 header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.event-01 header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.event-01 header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.event-01 header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.event-01 header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.event-01 header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-2 {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.event-01 .events-grid-2 .event-body-content {
	    margin-bottom: 50px;
	}
	.event-01 .events-grid-2 .events-single-box {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-2 .events-single-box img {
	    width: 100%;
	    min-height: 280px;
	}
	.event-01 .events-grid-2 .events-single-box:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-2 .events-single-box:hover .event-info h3,
	.event-01 .events-grid-2 .events-single-box:hover a {
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-2 .event-info {
	    padding: 30px 35px;
	}
	.event-01 .events-grid-2 .event-info h3 {
	    transition: all 0.3s ease-in-out;
	    padding-bottom: 15px;
	}
	.event-01 .events-grid-2 .event-info .events-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 25px;
	}
	.event-01 .events-grid-2 .event-info .events-time span .event-icon {
	    padding-right: 2px;
	}
	.event-01 .events-grid-2 .event-info p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	    padding-bottom: 15px;
	}
	.event-01 .events-grid-2 .event-info a {
	    color: #666;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-2 .event-info a .events-btn-icon {
	    padding-left: 5px;
	    margin: 10px 0;
	}
	.event-01 .events-grid-2 .event-bottom-btn {
	    text-align: center;
	    margin: 18px 0;
	}
	.event-01 .events-grid-2 .event-bottom-btn .view-more-item {
	    background: #ec7a11;
	    color: #333333;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    padding: 18px 50px;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	.event-01 .events-grid-3 {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.event-01 .events-grid-3 .event-body-content {
	    margin-bottom: 50px;
	}
	.event-01 .events-grid-3 .events-single-box {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-3 .events-single-box img {
	    width: 100%;
	    min-height: 250px;
	}
	.event-01 .events-grid-3 .events-single-box:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-3 .events-single-box:hover .event-info h3,
	.event-01 .events-grid-3 .events-single-box:hover a {
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-3 .event-info {
	    padding: 30px 15px;
	}
	.event-01 .events-grid-3 .event-info h3 {
	    transition: all 0.3s ease-in-out;
	    padding-bottom: 15px;
	}
	.event-01 .events-grid-3 .event-info .events-time span {
	    color: #666666;
	    font-size: 13px;
	    margin-right: 15px;
	}
	.event-01 .events-grid-3 .event-info .events-time span .event-icon {
	    padding-right: 2px;
	}
	.event-01 .events-grid-3 .event-info p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	    padding-bottom: 15px;
	}
	.event-01 .events-grid-3 .event-info a {
	    color: #666;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-grid-3 .event-info a .events-btn-icon {
	    padding-left: 5px;
	    margin: 10px 0;
	}
	.event-01 .events-grid-3 .event-bottom-btn {
	    text-align: center;
	    margin: 18px 0;
	}
	.event-01 .events-grid-3 .event-bottom-btn .view-more-item {
	    background: #ec7a11;
	    color: #333333;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    padding: 18px 50px;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	.event-01 .events-list-03 {
	    background: #ffffff;
	    padding: 100px 0;
	}
	.event-01 .events-list-03 .events-full-box {
	    margin-bottom: 50px;
	}
	.event-01 .events-list-03 .events-single-box {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-list-03 .events-single-box img {
	    width: 100%;
	}
	.event-01 .events-list-03 .events-single-box:hover {
	    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-list-03 .events-single-box:hover .event-info h3,
	.event-01 .events-list-03 .events-single-box:hover a {
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-list-03 .event-info {
	    padding: 42px;
	}
	.event-01 .events-list-03 .event-info h3 {
	    transition: all 0.3s ease-in-out;
	    padding-bottom: 15px;
	}
	.event-01 .events-list-03 .event-info .events-time span {
	    color: #666666;
	    font-size: 14px;
	    margin-right: 25px;
	}
	.event-01 .events-list-03 .event-info .events-time span .event-icon {
	    padding-right: 2px;
	}
	.event-01 .events-list-03 .event-info p {
	    margin: 0;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    color: #666666;
	    padding-bottom: 15px;
	}
	.event-01 .events-list-03 .event-info a {
	    color: #666;
	    margin: 0px;
	    text-decoration: none;
	    text-transform: uppercase;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    font-size: 14px;
	    transition: all 0.3s ease-in-out;
	}
	.event-01 .events-list-03 .event-info a .events-btn-icon {
	    padding-left: 5px;
	}
	.event-01 .events-list-03 .event-bottom-btn {
	    text-align: center;
	    margin: 18px 0;
	}
	.event-01 .events-list-03 .event-bottom-btn .view-more-item {
	    background: #ec7a11;
	    color: #333333;
	    font-size: 15px;
	    font-family: 'Montserrat', sans-serif;
	    font-weight: 600;
	    padding: 18px 50px;
	    text-decoration: none;
	    text-transform: uppercase;
	}
	/*====================================================================================================
										===[ Contact Form Style ]===
=====================================================================================================*/
	
	.contact header .header-body {
	    background: url("../images/Single_cources/banner-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.contact header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.contact header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.contact header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.contact header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.contact header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.contact header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.contact header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.contact .contact-area-02 {
	    padding: 100px 0;
	}
	.contact .contact-area-02 .contact-info h2 {
	    font-size: 30px;
	    text-align: left;
	    padding-bottom: 10px;
	}
	.contact .contact-area-02 .contact-info .content-sub_p {
	    font-size: 15px;
	    font-weight: 500;
	    position: relative;
	    padding-bottom: 10px;
	    color: #666;
	}
	.contact .contact-area-02 .contact-info .contact-box {
	    margin-top: 60px;
	}
	.contact .contact-area-02 .contact-info .contact-box .single-address-box {
	    padding: 30px 0;
	    border-top: 1px solid #ec7a11;
	}
	.contact .contact-area-02 .contact-info .contact-box .single-address-box .single-address i {
	    float: left;
	    font-size: 25px;
	    height: 40px;
	    line-height: 30px;
	    width: 30px;
	    color: #ec7a11;
	    margin-right: 15px;
	}
	.contact .contact-area-02 .contact-info .contact-box .single-address-box .single-address h4 {
	    margin: 0;
	    padding-bottom: 5px;
	}
	.contact .contact-area-02 .contact-info .contact-box .single-address-box .single-address p {
	    margin: 0;
	}
	.contact .contact-area-02 .contact-info .contact-box .single-address-box ul {
	    margin: 0px;
	}
	.contact .contact-area-02 .contact-info .contact-box .single-address-box ul li {
	    margin: 0 5px;
	    display: inline-block;
	}
	.contact .contact-area-02 .contact-info .contact-box .single-address-box ul li i {
	    border: 1px solid #ec7a11;
	    border-radius: 100%;
	    color: #ec7a11;
	    height: 40px;
	    padding: 10px;
	    width: 40px;
	    font-size: 18px;
	    text-align: center;
	    transition: all 0.3s ease-in-out;
	}
	.contact .contact-area-02 .contact-info .contact-box .single-address-box ul li i:hover {
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
	    transition: all 0.3s ease-in-out;
	    background: #ec7a11;
	    color: #ffffff;
	}
	.contact .contact-area-02 .contact-form .contact-title-btm h2 {
	    font-size: 30px;
	    text-align: left;
	    padding-bottom: 10px;
	}
	.contact .contact-area-02 .contact-form .contact-title-btm .content-sub_p {
	    font-size: 15px;
	    font-weight: 500;
	    position: relative;
	    padding-bottom: 10px;
	    text-align: left;
	    color: #666;
	}

	.contact .contact-area-02 .contact-form .input-contact-form {
	    margin-top: 50px;
	}
	.contact .contact-area-02 .contact-form .input-contact-form form input {
	    height: 40px;
	}
	.contact .contact-area-02 .contact-form .input-contact-form form input,
	.contact .contact-area-02 .contact-form .input-contact-form form textarea {
	    background-color: transparent;
	    border-radius: 0;
	    box-shadow: none;
	    font-size: 15px;
	    margin: 0px;
	    padding: 10px 20px;
	    outline: none;
	}
	.contact .contact-area-02 .contact-form .input-contact-form form input:focus,
	.contact .contact-area-02 .contact-form .input-contact-form form textarea:focus {
	    border: 1px solid #ec7a11;
	}
	.contact .contact-area-02 .contact-form .input-contact-form form input[type="submit"] {
	    background: #ec7a11;
	    border-radius: 0;
	    color: #fff;
	    border: none;
	    font-size: 15px;
	    font-weight: 500;
	    margin-top: 20px;
	    height: 40px;
	    text-transform: uppercase;
	}
	/*====================================================================================================
										===[ Register Style ]===
=====================================================================================================*/
	
	.register header .header-body {
	    background: url("../images/register-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.register header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.register header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.register header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.register header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.register header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.register header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.register header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.register .register-area {
	    background: #fafafa;
	    padding: 100px 0;
	}
	.register .register-area .learnpro-register-form {
	    background-color: #fff;
	    padding: 30px;
	}
	.register .register-area .learnpro-register-form .lead {
	    margin-bottom: 20px;
	    font-size: 18px;
	    font-weight: 600;
	    line-height: 1.4;
	    text-align: center;
	    text-transform: uppercase;
	}
	.register .register-area .learnpro-register-form select,
	.register .register-area .learnpro-register-form textarea,
	.register .register-area .learnpro-register-form input[type="text"],
	.register .register-area .learnpro-register-form input[type="password"],
	.register .register-area .learnpro-register-form input[type="datetime"],
	.register .register-area .learnpro-register-form input[type="datetime-local"],
	.register .register-area .learnpro-register-form input[type="date"],
	.register .register-area .learnpro-register-form input[type="month"],
	.register .register-area .learnpro-register-form input[type="time"],
	.register .register-area .learnpro-register-form input[type="week"],
	.register .register-area .learnpro-register-form input[type="number"],
	.register .register-area .learnpro-register-form input[type="email"],
	.register .register-area .learnpro-register-form input[type="url"],
	.register .register-area .learnpro-register-form input[type="search"],
	.register .register-area .learnpro-register-form input[type="tel"],
	.register .register-area .learnpro-register-form input[type="color"],
	.register .register-area .learnpro-register-form .uneditable-input {
	    min-height: 50px;
	    font-weight: 400;
	    display: block;
	    padding: 12px 15px;
	    font-size: 14px;
	    line-height: 1.428571429;
	    color: #555;
	    background-color: transparent;
	    background-image: none;
	    border-radius: 4px;
	    box-shadow: none;
	    -webkit-box-shadow: none;
	    border: 1px solid #e9edf0;
	}
	.register .register-area .learnpro-register-form .register-btn .btn {
	    width: 100%;
	    border-radius: 3px;
	    margin-top: 15px;
	    background: #ec7a11;
	    color: #333;
	    border: none;
	    text-transform: uppercase;
	    font-weight: 700;
	    font-size: 15px;
	    padding: 15px 0;
	    box-shadow: none;
	}
	/*====================================================================================================
										===[ Login Style ]===
=====================================================================================================*/
	
	.login header .header-body {
	    background: url("../images/register-bg.jpg") no-repeat center center;
	    min-height: 360px;
	    background-size: cover;
	    position: relative;
	}
	.login header .header-body:before {
	    background: rgba(0, 0, 0, 0.5);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
	    left: 0;
	}
	.login header .header-body .intro-text {
	    text-align: center;
	    margin-top: 80px;
	}
	.login header .header-body .intro-text h1 {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 45px;
	    font-weight: 700;
	    color: #ffffff;
	    margin: 0;
	}
	.login header .header-body .intro-text p {
	    margin-top: 15px;
	}
	.login header .header-body .intro-text p span {
	    font-size: 14px;
	    color: #ffffff;
	}
	.login header .header-body .intro-text p span a {
	    color: #ffffff;
	    text-decoration: none;
	    transition: all 0.3s ease-in-out;
	}
	.login header .header-body .intro-text p span a:hover {
	    color: #ec7a11;
	    transition: all 0.3s ease-in-out;
	}
	.login .login-area {
	    background: #fafafa;
	    padding: 100px 0;
	}
	.login .login-area .learnpro-register-form {
	    background-color: #fff;
	    padding: 30px;
	}
	.login .login-area .learnpro-register-form p{
		line-height: 23px;
		margin-bottom: 35px;
	}
	.login .login-area .learnpro-register-form .lead {
	    margin-bottom: 20px;
	    font-size: 18px;
	    font-weight: 600;
	    line-height: 1.4;
	    text-align: center;
	    text-transform: uppercase;
	}
	.login .login-area .learnpro-register-form select,
	.login .login-area .learnpro-register-form textarea,
	.login .login-area .learnpro-register-form input[type="text"],
	.login .login-area .learnpro-register-form input[type="password"],
	.login .login-area .learnpro-register-form input[type="datetime"],
	.login .login-area .learnpro-register-form input[type="datetime-local"],
	.login .login-area .learnpro-register-form input[type="date"],
	.login .login-area .learnpro-register-form input[type="month"],
	.login .login-area .learnpro-register-form input[type="time"],
	.login .login-area .learnpro-register-form input[type="week"],
	.login .login-area .learnpro-register-form input[type="number"],
	.login .login-area .learnpro-register-form input[type="email"],
	.login .login-area .learnpro-register-form input[type="url"],
	.login .login-area .learnpro-register-form input[type="search"],
	.login .login-area .learnpro-register-form input[type="tel"],
	.login .login-area .learnpro-register-form input[type="color"],
	.login .login-area .learnpro-register-form .uneditable-input {
	    min-height: 50px;
	    font-weight: 400;
	    display: block;
	    padding: 12px 15px;
	    font-size: 14px;
	    line-height: 1.428571429;
	    color: #555;
	    background-color: transparent;
	    background-image: none;
	    border-radius: 4px;
	    box-shadow: none;
	    -webkit-box-shadow: none;
	    border: 1px solid #e9edf0;
	}
	.login .login-area .learnpro-register-form .register-btn .btn {
	    width: 100%;
	    border-radius: 3px;
	    margin-bottom: 15px;
	    background: #ec7a11;
	    color: #333;
	    border: none;
	    text-transform: uppercase;
	    font-weight: 700;
	    font-size: 15px;
	    padding: 15px 0;
	    box-shadow: none;
	}
	.login .login-area .learnpro-register-form a {
	    color: #ec7a11;
	    text-decoration: none;
	}
	/*# sourceMappingURL=style.css.map */

/*=======[ Update Versoin 1.0 CSS ]========*/
header .header-body .is-sticky .edu-navbar {
    padding: 10px 0;
    background: #ffffff;
    z-index: 1000;
    transition: 0.5s ease;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
}
header .header-body .is-sticky .edu-navbar .edu-nav .nav li a:before{
   margin: -8px 0;
}
header .header-body .is-sticky .edu-navbar .edu-nav .nav li a:before{
   margin: -8px 0;
}
header .header-body .is-sticky .edu-navbar .edu-nav .nav li ul.dropdown {
    margin-top: 8px;
}
header .header-body .edu-navbar .edu-nav .nav li:last-child a {
    padding: 20px 0;
}
.home_version_04 .header-body {
    height: 63px;
}
.home_version_04 .header-body .is-sticky .edu-navbar{
    padding: 10px 0;
}
.home_version_04 .header-body .edu-navbar .edu-nav .nav li a {
    padding: 21px 0;
}
.home_version_04 .header-body .edu-navbar .edu-nav .nav li:last-child a {
    padding: 22px 0;
}
.home_version_04 .header-body .is-sticky .edu-navbar .edu-nav .nav li a:before{
    margin: -11px 0;
}
.home_version_04 .header-body .is-sticky .edu-navbar .edu-nav .nav li ul.dropdown{
    margin-top: 11px;
    padding: 0px 0px;
    transition: all 0.5s ease-in-out;
}
.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown {
    padding: 0px 0px;
}
.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 {
    top: 0px;
    padding: 0px 0px;
    transition: all 0.5s ease-in-out;
}
.home_version_04 .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list_3 {
    top: 0px;
    padding: 0px 0px;
    transition: all 0.5s ease-in-out;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li {
    margin: 0;
}
header .header-body .edu-navbar .edu-nav .nav li a {
    padding-top: 0px;
}   
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown {
    max-height: 0%;
    visibility: hidden;
    padding: 0px 0px;
    opacity:0;
    transition: all 0.5s ease-in-out;
    border-top: 3px solid #fff;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown::before {
    position: absolute;
    top: 0px;
    left: 20px;
    height: 10px;
    width: 10px;
    background: #fff;
    transform: rotate(45deg);
    content: "";
    transition: all 0.5s ease-in-out;
}
header .header-body .edu-navbar .edu-nav .nav li:hover ul.dropdown::before{
    visibility: visible;
    top: -8px;
}
header .header-body .edu-navbar .edu-nav .nav li:hover ul.dropdown {
    opacity: 1;
    visibility: visible;
    max-height: 1000%;
    padding: 0px 0px;
    transition: all 0.5s ease-in-out;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li:first-child {
    background: #ffffff;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a {
    margin-left: 0px;
    padding: 10px 30px 12px;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li a:hover {
    margin-left: 0px;
    background: #ec7a11;
    color: #ffffff;
}
header .header-body .edu-navbar .edu-nav .nav li a {
    padding: 23px 0;
    margin-bottom: -3px;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 {
    max-height: 0%;
    visibility: hidden;
    padding: 0px 0px;
    opacity:0;
    transition: all 0.5s ease-in-out;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list_3 {
    max-height: 0%;
    visibility: hidden;
    padding: 0px 0px;
    opacity:0;
    transition: all 0.5s ease-in-out;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list-box-03 {
    position: relative;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown li .dropdown-list_2 .dropdown-list-box-03:hover .dropdown-list_3 {
    opacity: 1;
    visibility: visible;
    max-height: 1000%;
    transition: all 0.5s ease-in-out;
    padding: 0px 0px;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown .dropdown-list-box-02:hover .dropdown-list_2 {
    opacity: 1;
     visibility: visible;
    max-height: 1500%;
    transition: all 0.5s ease-in-out;
    padding: 0px 0px;
}
header .header-body .edu-navbar .edu-nav .nav li ul.dropdown .dropdown-list-box-02 a:hover .menu-icon {
    color: #ffffff;
    transition: all 0.5s ease-in-out;
}	
span.c-author {
    position: absolute;
    top: 18px;
    left: 58px;
    width: 200px;
}
span.b-active {
    color: #ec7a11 !important;
}
.blog-area h3, .post_1_area h3{
    font-size: 28px;
}